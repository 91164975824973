.facts {
  @include use-responsive-sizing(
    padding-top padding-bottom,
    responsive-map(xs 4rem, xl 7.2rem)
  );
  @include use-container($max-width: $max-width-content-medium);

  display: grid;
  grid-template-columns: 1fr;
  color: $color-blue-1;
  line-height: 1.5;

  @include mq($from: l) {
    gap: 4.8rem;
    grid-template-columns: 5fr 7fr;
  }

  @include mq($from: xl) {
    gap: 8rem;
    grid-template-columns: 4fr 8fr;
  }
}

.facts__intro,
.facts__items {
  width: 100%;
}

.facts__intro {
  position: relative;
  margin-bottom: 3.2rem;

  &-sticky-container {
    @include mq($from: l) {
      position: sticky;
      top: 2.4rem;
    }
  }

  figure {
    width: 16rem;
    height: auto;
  }

  .intro__visual,
  .intro__headline {
    margin-bottom: 3.2rem;
  }

  .intro__copy {
    font-size: 2.1rem;
  }
}

.facts__items {
  .fact {
    display: block;
    gap: 6.4rem;
    border: $border-boxes;
    padding: 3.2rem;
    margin-bottom: 3.2rem;
    font-size: 1.8rem;
    grid-template-columns: auto 1fr;

    @include mq($from: m) {
      display: grid;
    }
    @include mq($from: l) {
      display: block;
      padding: 4.8rem;
    }
    @include mq($from: xl) {
      display: grid;
    }

    figure {
      width: 6.4rem;
      height: auto;
    }
  }

  .fact__list {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      border-bottom: 1px solid $color-blue-3;
      padding: 2.4rem 0;
    }
  }

  .fact__title {
    margin-bottom: 2.4rem;
  }

  .fact__visual,
  .fact__copy {
    margin-bottom: 3.2rem;
  }
}
