:root {
  @include use-responsive-sizing(--link-box-padding, responsive-map(xs 2.4rem, xl 4rem));
}

.link-box {
  background-color: $color-blue-4;
  padding: var(--link-box-padding);
}

.link-box__description {
  @include use-responsive-sizing(font-size, responsive-map(xs 1.6rem, xl 2.2rem));

  color: $color-blue-1;
  line-height: $line-height-body;
  margin-top: calc(var(--link-box-padding) * 0.5);
}

.link-box__links {
  margin-top: calc(var(--link-box-padding) * 0.75);
}

.link-box__link {
  display: block;

  & + & {
    margin-top: calc(var(--link-box-padding) * 0.5);
  }
}
