.redirect-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  background: $color-white;
  z-index: 9999;
  pointer-events: none;
  transition: opacity 0.5s ease;

  &.hidden {
    opacity: 0;
  }
}
