@keyframes show-copyright-tooltip {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.copyright {
  display: inline-block;
}

.copyright__button {
  background-color: $color-blue-1;
  box-shadow: inset 0 0 0 1px $color-blue-1;
  color: $color-white;
  cursor: pointer;
  font-size: 2rem;
  height: 3.6rem;
  line-height: 0;
  padding: 0.8rem;
  transition-property: background-color, color;
  user-select: none;
  width: 3.6rem;

  &:hover,
  &:not(.has-invisible-focus):focus {
    background-color: $color-white;
    color: $color-blue-1;
  }
}

.copyright__tooltip {
  --link-color: #{$color-white};
  --link-hover-color: #{$color-white};

  animation: show-copyright-tooltip $duration-default;
  background-color: $color-blue-1;
  color: $color-white;
  font-size: 1.6rem;
  line-height: $line-height-body;
  padding: 2rem;
  position: absolute;
  text-align: left;
  width: 25rem;
}
