.field-group-repeater__label {
  margin-bottom: 2rem;
}

.field-group-repeater__field-group-row {
  border: 2px solid $color-blue-1;
  border-radius: 1rem;
  margin-bottom: 2.4rem;
  padding: 2.4rem;
  position: relative;
}

.field-group-repeater__field-group-button {
  background-color: $color-blue-1;
  border: 1px solid $color-blue-1;
  border-radius: 50%;
  color: $color-white;
  height: 3.2rem;
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(50%, -50%);
  transition-property: background-color, color;
  width: 3.2rem;

  &:hover,
  &:focus {
    background-color: $color-white;
    color: $color-blue-1;
  }
}

.field-group-repeater__description {
  margin-bottom: 2rem;
}

.field-group-repeater__add {
  text-align: right;
}
