:root {
  --alert-font-size: 1.8rem;
}

.alert {
  @include use-responsive-sizing(font-size, (
    xs: var(--alert-font-size),
    m: calc(var(--alert-font-size) * 1.11),
    l: calc(var(--alert-font-size) * 1.22),
  ));

  background-color: $color-white;
  border-radius: 0.5rem;
  box-shadow: inset 0 0 0 0.1rem $color-gray;
  display: flex;
  line-height: $line-height-body;
  padding: 2rem;
}

.alert__icon {
  flex-shrink: 0;
  font-size: 4rem;
  line-height: 0;
  margin-right: 1.6rem;

  .alert--info & {
    color: $color-blue-2;
  }

  .alert--success & {
    color: $color-green;
  }

  .alert--warning & {
    color: $color-yellow;
  }

  .alert--error & {
    color: $color-red;
  }
}

.alert__text {
  align-self: center;
}
