.media-block {
  @include use-responsive-sizing(
    padding-top padding-bottom,
    responsive-map(xs 4rem, xl 7.2rem)
  );
  margin-bottom: 11rem;
  display: flex;
  flex-direction: column;

  @include mq($from: m) {
    flex-direction: row;
  }

  &.reversed {
    flex-direction: column-reverse;

    @include mq($from: m) {
      flex-direction: row;

      .media-block__image {
        margin-right: 0;
      }
    }

    .media-block__image {
      @include use-responsive-sizing(margin-left, $gaps, $start-breakpoint: m);
    }
  }

  &__container {
    @include use-container($max-width: $max-width-content-medium);
  }

  &__left,
  &__right {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: start;

    @include mq($from: m) {
      justify-content: center;
      max-width: 50%;
    }
  }

  &__image {
    @include use-responsive-sizing(
      margin-bottom,
      map-multiply($gaps, 1.5),
      $end-breakpoint: s,
      $scoped: true
    );
    @include use-responsive-sizing(margin-right, $gaps, $start-breakpoint: m);

    margin-bottom: 4rem;
    max-width: 50%;

    @include mq($from: m) {
      margin-bottom: 0;
      max-width: 425px;
    }

    &.rounded {
      background-color: $color-blue-3;
      border-radius: 50%;
      max-width: 50%;
      padding: 4rem;

      @include mq($from: m) {
        max-width: 425px;
        padding: 8rem;
      }
    }
  }

  &__right {
    .media-block__image {
      @include mq($from: m) {
        align-self: end;
      }
    }
  }

  &__copy {
    margin-top: 2rem;
  }

  &__list {
    margin-top: 2rem;

    &-item {
      border-bottom: 1px solid $color-gray-2;
      padding: 1.6rem 0;

      .paragraph {
        @include font-paragraph;
      }
    }
  }

  &__button {
    margin-top: 4rem;
  }

  .fw-normal {
    font-weight: normal !important;
  }
}
