.slide {
  --gradient-color: #ffffff55;

  @include use-responsive-sizing(
    min-height,
    responsive-map(xs 450px, xl 800px)
  );

  display: flex;
  flex-direction: row;
  background-color: $color-blue-3;
  color: $color-blue-1;
  height: 100%;
  overflow: hidden;
  position: relative;
  z-index: 1;

  .headline {
    margin-bottom: 0;
  }
}

.slide--large {
  @include use-responsive-sizing(min-height, responsive-map(xs 55rem, xl 92rem));
}

.slide--inverted {
  --headline-color: #{$color-white};
  --gradient-color: #00000066;
  background-color: $color-blue-2;
  color: $color-white;
}

.slide--transparent {
  background-color: transparent;
}

.slide--text-position-left {
  --slide-text-position: left;
  --slide-item-position: flex-start;
}

.slide--text-position-center {
  --slide-text-position: center;
  --slide-item-position: center;
}

.slide--text-position-right {
  --slide-text-position: right;
  --slide-item-position: flex-end;
}

.slide__gradient {
  position: absolute;
  z-index: $z-index-background;
  width: 100%;
  height: 100%;
  background: var(--gradient-color);
  background-blend-mode: multiply;

  @include mq($from: l) {
    background: radial-gradient(
      100% 100% at 100% 100%,
      transparent 50%,
      var(--gradient-color) 100%
    );
  }
}

.slide__image {
  display: flex;
  flex-direction: column;
  inset: 0;
  position: absolute;
  z-index: -1;
}

.slide__video {
  flex-grow: 1;
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.slide__inner {
  @include use-container();

  @include use-responsive-sizing(padding-top, $inner-container-padding);
  @include use-responsive-sizing(padding-bottom, $inner-container-padding);

  text-align: left;
  align-self: flex-end;
  padding-top: 12px;
  padding-bottom: 12px;

  @include mq($from: m) {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  @include mq($from: l) {
    align-self: center;
    padding-top: 150px;
    padding-bottom: 150px;
  }
}

.slide__content {
  @include use-responsive-sizing(width, (
    xs: column-width(xs, 12),
    s: column-width(s, 12),
    m: column-width(m, 6),
    l: column-width(l, 7),
    xl: column-width(xl, 7),
  ));

  .slide--text-position-center & {
    margin-left: auto;
    margin-right: auto;
  }

  .slide--text-position-right & {
    margin-left: auto;
  }

  &.slide__content--multiple {
    margin-bottom: 6rem;

    @include mq($from: m) {
      margin-bottom: 0;
    }
  }
}

.slide__person-image {
  @include use-container($start-breakpoint: m);
  @include use-responsive-sizing(padding-top, responsive-map(xs 10rem, xl 15rem), m);

  border-bottom: 2px solid $color-blue-1;
  margin-bottom: 4rem;
  margin-top: 4rem;

  @include mq($from: m) {
    align-items: flex-end;
    border-bottom: 0;
    display: flex;
    flex-direction: column;
    inset: 0;
    justify-content: flex-end;
    margin-bottom: 0;
    margin-top: 0;
    pointer-events: none;
    position: absolute;
  }
}

.slide__person-image-inner {
  @include use-responsive-sizing(margin-left margin-right, (xs: auto, m: 0));
  @include use-responsive-sizing(max-width, (xs: 40rem, m: 60rem));
  @include use-responsive-sizing(width, (
    m: column-width(m, 7),
    l: column-width(l, 6),
    xl: column-width(xl, 6),
  ));

  margin-left: auto;
  margin-right: auto;
  max-height: 50rem;
  overflow: hidden;
  transform: translateX(15%);

  @include mq($from: m) {
    max-height: none;
  }
}

.slide__description {
  @include use-responsive-sizing(font-size, responsive-map(xs 2.2rem, xl 2.8rem));
  @include use-responsive-sizing(margin-top, responsive-map(xs 2.4rem, xl 3.2rem));

  font-weight: $font-weight-medium;
  line-height: $line-height-body;
}

.slide__quote {
  @include use-responsive-sizing(font-size, responsive-map(xs 2rem, xl 2.4rem));
  @include use-responsive-sizing(margin-top, responsive-map(xs 2.4rem, xl 3.2rem));

  line-height: $line-height-body;

  @include mq($from: m) {
    display: flex;
  }
}

.slide__quote-icon {
  flex-shrink: 0;
  line-height: 0;
  margin-bottom: 1.2rem;

  @include mq($from: m) {
    margin-bottom: 0;
    margin-right: 1.6rem;
    margin-top: 0.4rem;
  }
}

.slide__list {
  @include use-responsive-sizing(margin-top, responsive-map(xs 2.4rem, xl 3.2rem));
}

.slide__list-item {
  @include use-responsive-sizing(font-size, responsive-map(xs 2rem, xl 2.4rem));
  @include use-responsive-sizing(justify-content, (xs: center, m: var(--slide-item-position)));

  display: flex;
  font-weight: $font-weight-medium;
  line-height: $line-height-body;

  & + & {
    margin-top: 1.2rem;
  }
}

.slide__links {
  @include use-responsive-sizing(column-gap, $gaps);
  @include use-responsive-sizing(column-count, (xs: 1, m: 2));

  margin-top: 2.4rem;
}

.slide__links-item {
  break-inside: avoid-column;
  display: block;

  & + & {
    margin-top: 1.2rem;
  }
}

.slide__list-item-icon {
  font-size: 3rem;
  line-height: 0;
}

.slide__list-item-text {
  align-self: center;
  margin-left: 1.2rem;
}

.slide__buttons {
  display: flex;
  flex-wrap: wrap;
  padding-top: 32px;
}
