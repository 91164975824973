.html-embed {
  background-color: $color-blue-4;
  min-height: 30rem;
  position: relative;
}

.html-embed--enabled {
  background-color: transparent;
}

.html-embed__details {
  inset: 0;
  position: absolute;
  z-index: 2;
}

.html-embed__details-inner {
  @include use-container();
  @include use-responsive-sizing(padding-top padding-bottom, $inner-container-padding);

  display: flex;
  flex-wrap: wrap;
  font-size: 2rem;
  height: 100%;
  justify-content: center;
  line-height: $line-height-body;

  @include mq($from: rem2px($inner-container-max-width)) {
    padding-bottom: $inner-container-max-padding;
    padding-top: $inner-container-max-padding;
  }
}

.html-embed__button {
  cursor: pointer;
  margin-bottom: 2rem;
}

.html-embed__notice {
  background-color: $color-white;
  cursor: unset;
  flex-basis: 100%;
  margin-top: auto;
  padding: 0.8rem 1.2rem;
  position: relative;
  z-index: 3;

  @include mq($from: m) {
    padding: 2rem;
  }
}
