.highlight-block {
  @include use-responsive-sizing(padding-top padding-bottom, responsive-map(xs 5.4rem, xl 7.2rem));
}

.highlight-block--blue-1 {
  --headline-color: #{$color-white};
  --paragraph-color: #{$color-white};
  --list-color: #{$color-white};
  --horizontal-rule-color: #{$color-white};

  background-color: $color-blue-1;
  color: $color-white;
}

.highlight-block--blue-2 {
  --headline-color: #{$color-white};
  --paragraph-color: #{$color-white};
  --list-color: #{$color-white};
  --horizontal-rule-color: #{$color-white};

  background-color: $color-blue-2;
  color: $color-white;
}

.highlight-block--blue-3 {
  background-color: $color-blue-3;
  color: $color-blue-1;
}

.highlight-block__inner {
  @include use-container();
}

.highlight-block__content {
  @include use-clearfix();

  @include use-responsive-sizing(width, (
    s: column-width(s, 10),
    m: column-width(m, 10),
    l: column-width(l, 8),
    xl: column-width(xl, 6),
  ));

  margin-left: auto;
  margin-right: auto;
}

.highlight-block__headline {
  margin-bottom: 2.4rem;
}

.highlight-block__text {
  > * + * {
    @include use-responsive-sizing(margin-top, responsive-map(xs 3.2rem, xl 4rem));
  }

  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }
}

.highlight-block__horizontal-rule {
  margin-bottom: 4rem;
  margin-top: 4rem;
  width: 40%;
}

.highlight-block__buttons {
  @include use-responsive-sizing(justify-content, (xs: center, m: var(--slide-item-position)));

  display: flex;
  flex-wrap: wrap;
  margin: -0.8rem -1.6rem;
  padding-top: 4rem;
}

.highlight-block__button {
  padding: 0.8rem 1.6rem;
}
