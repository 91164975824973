.image {
  display: block;
  flex-grow: 1;
  line-height: 0;
  position: relative;
}

.image__placeholder {
  height: 100%;
  inset: 0;
  object-fit: cover;
  opacity: 0;
  position: absolute;
  transition-duration: 0;
  width: 100%;
  //z-index: 1;

  .js & {
    opacity: 1;
    transition-duration: $duration-long;
    transition-property: opacity;
  }

  .js .image--loaded & {
    opacity: 0;
    pointer-events: none;
  }
}

.image__img {
  font-size: 1.4rem;
  height: auto;
  position: relative;
  transition-duration: $duration-long;
  transition-property: opacity;
  width: 100%;
  //z-index: 2;

  .js & {
    opacity: 0;
  }

  .js .image--loaded & {
    opacity: 1;
  }

  .image--cover & {
    height: 100%;
    inset: 0;
    object-fit: cover;
    position: absolute;
    width: 100%;
  }
}
