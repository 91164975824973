button::-moz-focus-inner {
  border: 0;
}

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  @include use-outline();
}

:focus {
  @include use-outline();
}

:focus:not(:focus-visible) {
  outline: 0;
}
