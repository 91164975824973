.field-table__label {
  display: block;
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 1.6rem;
  width: 100%;
}

.field-table__table {
  margin-bottom: 1.6rem;
  width: 100%;
}

.field-table__table-row:nth-child(odd) {
  background-color: $color-blue-4;
}

.field-table__table-row:nth-child(even) {
  background-color: $color-blue-3;
}

.field-table__table-cell {
  padding: 0.5rem 1rem;
  text-align: left;
  vertical-align: middle;
}

.field-table__table-cell--head {
  background-color: $color-blue-1;
  color: $color-white;
  font-weight: bold;
  padding: 1rem;
}

.field-table__table-cell--action {
  width: 2.6rem;
}

.field-table__add {
  text-align: right;
}

.field-table__description {
  display: flex;
  font-size: 1.6rem;
  margin-top: 2rem;
}

.field-table__description-icon {
  flex-shrink: 0;
  margin-right: 0.8rem;
}
