.article-teaser {
  flex-grow: 1;
  padding: var(--teaser-padding);
}

.article-teaser__image-container {
  margin-bottom: 2.4rem;
  overflow: hidden;
}

.article-teaser__image {
  transition-property: transform;

  .article-teaser:hover &,
  .article-teaser:focus-within & {
    transform: scale(1.05);
  }
}

.article-teaser__duration {
  color: var(--headline-kicker-color, var(--headline-color));
  float: right;
  font-size: var(--headline-kicker-font-size, 1.6rem);
  line-height: $line-height-body;
  margin-left: 3.2rem;
}

.article-teaser__description {
  @include use-responsive-sizing(font-size, responsive-map(xs 1.6rem, xl 2.2rem));

  color: $color-blue-1;
  line-height: $line-height-body;
  margin-top: 1.6rem;
}
