.job-search-bar__inner {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: -1.2rem -0.8rem;

  > * {
    padding: 1.2rem 0.8rem;
  }

  .job-search-bar--center & {
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  @include mq($from: m) {
    flex-direction: row;
  }
}

.job-search-bar--inverted {
  --headline-color: #{$color-white};
}

.job-search-bar__headline {
  flex-basis: 100%;
  padding-bottom: 0.8rem;
}

.job-search-bar__button {
  @include use-responsive-sizing(--button-height, responsive-map(xs 4.8rem, xl 5.4rem));

  position: relative;
  width: 26rem;
}

.job-search-bar__loading {
  align-items: center;
  background-color: $color-blue-2;
  border-radius: calc(var(--button-height) / 2);
  color: $color-white;
  display: flex;
  font-size: 2rem;
  inset: 0;
  justify-content: center;
  line-height: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  transition-property: opacity;

  .is-requesting & {
    opacity: 1;
  }
}
