.video {
  padding-top: 56.25%;
  position: relative;
  z-index: 1;
}

.video-layout-boxed {
  @include use-container($max-width: 1140px);

  padding-top: 2rem;
  padding-bottom: 2rem;

  @include mq($from: m) {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  @include mq($from: l) {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
}

.video-background-white {
  background-color: $color-white;
}

.video-background-blue {
  background-color: $color-blue-3;
}

.video__container {
  background-color: $color-blue-1;
  inset: 0;
  position: absolute;
}

.video__iframe {
  height: 100%;
  inset: 0;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.video__preview {
  display: flex;
  flex-direction: column;
  inset: 0;
  position: absolute;
  z-index: 1;
}

.video__details {
  inset: 0;
  position: absolute;
  z-index: 2;
}

.video__details-inner {
  @include use-container();
  @include use-responsive-sizing(
    padding-top padding-bottom,
    $inner-container-padding
  );

  color: $color-white;
  display: flex;
  flex-wrap: wrap;
  font-size: 2rem;
  height: 100%;
  justify-content: space-between;
  line-height: $line-height-body;

  @include mq($from: rem2px($inner-container-max-width)) {
    padding-bottom: $inner-container-max-padding;
    padding-top: $inner-container-max-padding;
  }
}

.video__play {
  align-items: center;
  display: flex;
  height: 5.4rem;
  user-select: none;
}

.video__play-button {
  background-color: $color-blue-2;
  border-radius: 50%;
  font-size: 2.4rem;
  height: 5.4rem;
  line-height: 0;
  margin-right: 1.6rem;
  padding: 1.5rem;
  transition-property: background-color, color;
  width: 5.4rem;

  .video__play:hover &,
  .video__play:focus & {
    background-color: $color-white;
    color: $color-blue-1;
  }
}

.video__duration {
  line-height: 5.4rem;
}

.video__notice {
  background-color: $color-white;
  flex-basis: 100%;
  margin-top: auto;
  padding: 0.8rem 1.2rem;
  position: relative;
  z-index: 3;

  @include mq($from: m) {
    padding: 2rem;
  }
}
