.grid-overlay {
  bottom: 0;
  left: 0;
  opacity: 0.5;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99999;
}

.grid-overlay__inner {
  @include use-container();

  display: flex;
  height: 100%;
}

.grid-overlay__column {
  background-color: #f00;
  flex-grow: 1;
  flex-shrink: 0;

  & + & {
    @include use-responsive-sizing(margin-left, $gaps);
  }
}
