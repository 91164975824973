:root {
  --figure-text-color: #{$color-blue-1};
}
.figure {
  display: block;

  @include mq($from: m) {
    .size-sm {
      margin-left: auto;
      margin-right: auto;
      width: calc((100% - 44rem) / 12 * 6 + 20rem);
    }

    .size-md {
      margin-left: auto;
      margin-right: auto;
      width: calc((100% - 44rem) / 12 * 9 + 20rem);
    }
  }

  &--inverted {
    --figure-text-color: #{$color-white};
  }
}

.figure__figure {
  position: relative;
}

.figure__copyright {
  bottom: 0;
  position: absolute;
  right: 0;
}

.figure__caption {
  color: $color-blue-1;
  font-size: 1.4rem;
  font-weight: $font-weight-medium;
  line-height: $line-height-body;
  margin-top: 0.8rem;
  text-align: left;
}

.figure__content {
  position: absolute;
  z-index: 1;
  width: 100%;
  padding: 0 20%;
  bottom: 20%;
}

.figure__description {
  @include use-responsive-sizing(
    font-size,
    responsive-map(xs 2.2rem, xl 2.8rem)
  );
  font-weight: $font-weight-medium;
  line-height: $line-height-body;
  text-align: center;
  color: var(--figure-text-color);
}

.figure__button {
  text-align: center;
  margin-top: 2.4rem;
}
