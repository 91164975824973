:root {
  @include use-responsive-sizing(--page-content-gap, responsive-map(xs 3.2rem, xl 4rem));
  @include use-responsive-sizing(--page-content-section-gap, responsive-map(xs 4rem, xl 8rem));
}

.page-content {
  @include use-clearfix();
}

.page-content--blue-1 {
  --theme-background-color: #{$color-blue-1};
  --theme-foreground-color: #{$color-white};
  --paragraph-color: #{$color-white};
  --headline-color: #{$color-white};

  background-color: $color-blue-1;
}

.page-content--blue-2 {
  background-color: $color-blue-2;
}

.page-content--blue-3 {
  background-color: $color-blue-3;
}

.page-content__header {
  position: relative;
  z-index: 1;

  .page-content--with-dropdown-navigation & .slide {
    padding-bottom: 3rem;
  }

  .page-content--with-dropdown-navigation & .stage__navigation {
    bottom: 3rem;
  }
}

.page-content__header-inner {
  @include use-container();

  padding-top: var(--page-content-section-gap);
}

.page-content__header-content {
  @include use-responsive-sizing(width, (
    l: column-width(l, 10),
    xl: column-width(xl, 10),
  ));

  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.page-content__categories {
  padding-bottom: 3rem;

  .button {
    margin: .5rem;
  }
}

.page-content__headline {
  @include use-responsive-sizing(width, (
    xs: column-width(xs, 10),
    s: column-width(s, 10),
    m: column-width(m, 8, $number-of-columns: 10),
    l: column-width(l, 8, $number-of-columns: 10),
    xl: column-width(xl, 8, $number-of-columns: 10),
  ));

  margin-left: auto;
  margin-right: auto;
}

.page-content__date {
  @include use-responsive-sizing(margin-top, responsive-map(xs 2rem, xl 3.2rem));

  color: $color-blue-1;
  display: block;
  font-size: 1.6rem;
}

.page-content__subtitle {
  @include use-responsive-sizing(margin-top, responsive-map(xs 2rem, xl 3.2rem));
  @include use-responsive-sizing(width, (
    xs: column-width(xs, 10),
    s: column-width(s, 10),
    m: column-width(m, 8, $number-of-columns: 10),
    l: column-width(l, 8, $number-of-columns: 10),
    xl: column-width(xl, 8, $number-of-columns: 10),
  ));

  color: $color-blue-1;
  display: block;
  font-size: 2.2rem;
  font-weight: $font-weight-medium;
  line-height: $line-height-body;
  margin-left: auto;
  margin-right: auto;
}

.page-content__image {
  margin-top: var(--page-content-section-gap);
}

.page-content__appendix {
  $cut-out-width: 4.8rem;
  $cut-out-height: 2.4rem;

  margin-top: $cut-out-height * -1;
  padding-top: $cut-out-height;
  position: relative;
  text-align: center;
  z-index: 2;

  &::before {
    @include use-triangle-cut-out($cut-out-width, $cut-out-height, 50%);

    background-color: $color-white;
    content: '';
    height: $cut-out-height;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.page-content__inner {
  @include use-container();
}

.page-content__content {
  @include use-clearfix();

  @include use-responsive-sizing(width, (
    s: column-width(s, 10),
    m: column-width(m, 10),
    l: column-width(l, 8),
    xl: column-width(xl, 6),
  ));

  margin-left: auto;
  margin-right: auto;
  padding-bottom: var(--page-content-section-gap);
  padding-top: var(--page-content-section-gap);

  > * + * {
    margin-top: var(--page-content-gap);
  }

  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }
}

.page-content--with-intro {
  .page-content__paragraph:first-of-type {
    @include use-responsive-sizing(font-size, responsive-map(xs 2.4rem, xl 3.2rem));

    font-weight: $font-weight-medium;
  }
}

.page-content__heading {
  @include use-responsive-sizing(margin-top, (
    xs: 4rem,
    xl: 4.8rem,
  ));

  + .page-content__paragraph {
    margin-top: 2rem;
  }
}

.page-content__horizontal-rule {
  @include use-responsive-sizing(width, (
    xs: column-width(xs, 4),
    s: column-width(s, 4, $number-of-columns: 10),
    m: column-width(m, 4, $number-of-columns: 8),
    l: column-width(l, 4, $number-of-columns: 8),
    xl: column-width(xl, 4, $number-of-columns: 6),
  ));

  clear: both;
  margin: var(--page-content-section-gap) auto;
}

.page-content__aside {
  @include use-responsive-sizing(margin-left margin-right, (
    s: column-width(s, -1, $number-of-columns: 10),
    m: column-width(m, -1, $number-of-columns: 10),
    l: column-width(l, -1, $number-of-columns: 8),
    xl: column-width(xl, -2, $number-of-columns: 6),
  ));
}

.page-content__aside--center {
  clear: both;
  margin: var(--page-content-section-gap) 0;
}

.page-content__aside--full {
  clear: both;
  margin-bottom: var(--page-content-section-gap);
  margin-top: var(--page-content-section-gap);
}

.page-content__aside--left,
.page-content__aside--right {
  @include use-responsive-sizing(width, (
    xs: 100%,
    s: column-width(s, 5, $number-of-columns: 10),
    m: column-width(m, 5, $number-of-columns: 10),
    l: column-width(l, 5, $number-of-columns: 8),
    xl: column-width(xl, 4, $number-of-columns: 6),
  ));

  @include use-responsive-sizing(margin-top margin-bottom, (
    xs: 4rem,
    xl: 4.8rem,
  ));
}

.page-content__aside--left {
  @include use-responsive-sizing(margin-right, $gaps, s);

  float: left;
}

.page-content__aside--right {
  @include use-responsive-sizing(margin-left, $gaps, s);

  float: right;
}

.page-content__table-of-contents {
  @include use-responsive-sizing(margin-left, (
    s: column-width(s, -1, $number-of-columns: 10),
    m: column-width(m, -1, $number-of-columns: 10),
    l: column-width(l, -1, $number-of-columns: 8),
    xl: column-width(xl, -2, $number-of-columns: 6),
  ));

  @include use-responsive-sizing(margin-right, (
    s: column-width(s, 1, $number-of-columns: 10),
    m: column-width(m, 1, $number-of-columns: 10),
    l: column-width(l, 1, $number-of-columns: 8),
    xl: column-width(xl, 1, $number-of-columns: 6),
  ));

  @include use-responsive-sizing(width, (
    xs: 100%,
    s: column-width(s, 5, $number-of-columns: 10),
    m: column-width(m, 4, $number-of-columns: 10),
    l: column-width(l, 4, $number-of-columns: 8),
    xl: column-width(xl, 4, $number-of-columns: 6),
  ));

  @include use-responsive-sizing(margin-top margin-bottom, (
    xs: 4rem,
    xl: 4.8rem,
  ));

  float: left;
}

.page-content__form {
  margin-bottom: var(--page-content-section-gap);
  margin-top: var(--page-content-section-gap);
}

.page-content__quote {
  @include use-responsive-sizing(margin-left margin-right, (
    s: column-width(s, -1, $number-of-columns: 10),
    m: column-width(m, -1, $number-of-columns: 10),
    l: column-width(l, -1, $number-of-columns: 8),
    xl: column-width(xl, -1, $number-of-columns: 6),
  ));

  clear: both;
  margin-bottom: var(--page-content-section-gap);
  margin-top: var(--page-content-section-gap);
}

.page-content__small-breaker {
  @include use-responsive-sizing(margin-left margin-right, (
    s: column-width(s, -1, $number-of-columns: 10),
    m: column-width(m, -1, $number-of-columns: 10),
    l: column-width(l, -1, $number-of-columns: 8),
    xl: column-width(xl, -2, $number-of-columns: 6),
  ));

  clear: both;
  margin-bottom: var(--page-content-section-gap);
  margin-top: var(--page-content-section-gap);
}

.page-content__accordion {
  clear: both;

  & + & {
    margin-top: 1.6rem;
  }
}

.page-content__breaker {
  @include use-break-out();

  clear: both;
  margin-top: var(--page-content-section-gap);

  &:not(:last-child) {
    margin-bottom: var(--page-content-section-gap);
  }

  & + & {
    margin-top: calc(var(--page-content-section-gap) * -1);
  }
}

.page-content__breaker--teaser-grid {
  padding-bottom: var(--page-content-section-gap);
  padding-top: var(--page-content-section-gap);

  & + & {
    padding-top: 0;
  }
}

.page-content__breaker--transparent {
  margin-bottom: 0;
  margin-top: 0;
}

.page-content:not(.page-content--is-article) .page-content__breaker:first-child {
  margin-top: calc(var(--page-content-section-gap) * -1);
}

.page-content.page-content--blue-1 .page-content__breaker--transparent:first-child,
.page-content.page-content--blue-2 .page-content__breaker--transparent:first-child,
.page-content.page-content--blue-3 .page-content__breaker--transparent:first-child {
  margin-top: calc(var(--page-content-section-gap) * -2);
}

.page-content__content:last-child .page-content__breaker:last-child {
  margin-bottom: calc(var(--page-content-section-gap) * -1);
}

.page-content__footer {
  @include use-responsive-sizing(padding-top, responsive-map(xs 2.4rem, xl 4rem));

  @include use-responsive-sizing(width, (
    s: column-width(s, 10),
    m: column-width(m, 8),
    l: column-width(l, 8),
    xl: column-width(xl, 6),
  ));

  border-top: 2px solid var(--theme-foreground-color);
  margin-left: auto;
  margin-right: auto;
  margin-top: var(--page-content-section-gap);
  padding-bottom: var(--page-content-section-gap);
}

.page-content__share-buttons {
  display: flex;
  flex-wrap: wrap;
  margin: -0.8rem;
}

.page-content__share-button {
  display: block;
  padding: 0.8rem;
}
