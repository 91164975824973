@keyframes pager-label-fade-in {
  0% {
    opacity: 0;
    transform: translateX(-10rem);
  }

  25% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes pager-label-fade-out {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 0;
    transform: translateX(7rem);
  }
}

:root {
  --pager-background-color: rgba(#{red($color-blue-1), green($color-blue-1), blue($color-blue-1)}, 0.2);
  --pager-foreground-color: #{$color-blue-1};
  --pager-button-color: var(--pager-foreground-color);
  --pager-button-background-color: var(--pager-background-color);
  --pager-button-focus-color: #{$color-white};
  --pager-button-focus-background-color: var(--pager-foreground-color);
}

.pager {
  align-items: flex-end;
  display: flex;
  justify-content: flex-start;
}

.pager__pages {
  display: flex;
  width: calc((var(--count) - 1) * 6rem + 11rem);

  .pager--compact & {
    width: calc(var(--count) * 6rem - 2rem);
  }
}

.pager__page {
  display: block;

  & + & {
    margin-left: 2rem;
  }
}

.pager__page-button {
  --progress: 0%;

  cursor: pointer;
  text-align: left;
  transition-duration: 1s;
  transition-property: width;
  user-select: none;
  width: 4rem;
  will-change: width;

  &[disabled] {
    cursor: default;
  }

  &[aria-current='true'] {
    --progress: 100%;

    width: 11rem;
  }

  .pager--compact & {
    padding-bottom: 2rem;
    padding-top: 2rem;
  }

  .pager--compact &[aria-current='true'] {
    width: 4rem;
  }
}

.pager__page-button-label {
  animation-duration: 1s;
  color: var(--pager-foreground-color);
  display: block;
  font-size: 1.6rem;
  opacity: 0;
  overflow: hidden;
  padding-bottom: 1.2rem;
  pointer-events: none;
  text-overflow: ellipsis;
  transition-property: opacity;
  white-space: nowrap;
  width: 11rem;

  .pager__page-button[data-fadeout] & {
    animation-name: pager-label-fade-out;
    animation-play-state: running;
  }

  .pager__page-button[aria-current='true'] & {
    animation-name: pager-label-fade-in;
    animation-play-state: running;
    opacity: 1;
  }

  .pager--compact & {
    display: none;
  }
}

.pager__page-button-progress {
  background-color: var(--pager-background-color);
  display: block;
  height: 0.2rem;
  overflow: hidden;
  position: relative;
  transition-property: background-color, transform;
  width: 100%;

  &::after {
    background-color: var(--pager-foreground-color);
    content: '';
    inset: 0;
    opacity: 0;
    position: absolute;
    transform: translateX(calc(-100% + var(--progress)));
    transform-origin: 100% 100%;
    transition-property: transform;
  }

  .pager__page-button[aria-current='true'] &::after {
    opacity: 1;
  }

  .pager__page-button:not([disabled]):hover &,
  .pager__page-button:not(.has-invisible-focus):focus & {
    background-color: var(--pager-foreground-color);
    transform: scaleY(5);
  }
}

.pager__autoplay {
  background-color: var(--pager-button-background-color);
  border-radius: 50%;
  color: var(--pager-button-color);
  font-size: 1.6rem;
  height: 3.6rem;
  margin-left: 3rem;
  padding: 1rem;
  transition-property: background-color, color;
  width: 3.6rem;

  &:hover,
  &:not(.has-invisible-focus).focus {
    background-color: var(--pager-button-focus-background-color);
    color: var(--pager-button-focus-color);
  }
}

.pager__autoplay-icon {
  display: none;
}

.pager__autoplay-icon--play {
  .pager__autoplay[aria-pressed='true'] & {
    display: block;
  }
}

.pager__autoplay-icon--pause {
  .pager__autoplay[aria-pressed='false'] & {
    display: block;
  }
}
