.contact-teaser {
  color: $color-blue-1;
  padding: var(--teaser-padding);
  position: relative;
}

.contact-teaser__person {
  display: flex;
  flex-direction: column;

  @include mq($from: s) {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.contact-teaser__person-image {
  flex-shrink: 0;
  margin-bottom: 2.4rem;
  max-width: 9.5rem;

  .contact-teaser--large-image & {
    max-width: none;
    width: 100%;
  }

  .contact-teaser:not(.contact-teaser--large-image) & {
    @include mq($from: s) {
      margin-bottom: 0;
      margin-right: 3.2rem;
    }
  }
}

.contact-teaser__person-headline {
  @include mq($from: s) {
    align-self: center;
  }
}

.contact-teaser__person-position {
  font-size: 1.8rem;
  line-height: $line-height-body;
  margin-top: 0.8rem;
}

.contact-teaser__footer {
  @include use-responsive-sizing(margin, map-multiply($gaps, -0.5));

  align-items: flex-end;
  display: flex;
  flex-wrap: wrap;
  padding-top: 2.4rem;
}

.contact-teaser__footer-section {
  @include use-responsive-sizing(padding, map-multiply($gaps, 0.5));

  align-items: stretch;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  > * + * {
    margin-top: 2.4rem;
  }
}

.contact-teaser__detail {
  font-size: 1.4rem;
  font-weight: $font-weight-medium;
  line-height: $line-height-body;

  & + & {
    margin-top: 0.4rem;
  }
}

.contact-teaser__detail-label {
  display: inline;

  &::after {
    content: ':';
  }
}

.contact-teaser__detail-value {
  display: inline;
}

.contact-teaser__more-link {
  @include use-responsive-sizing(font-size, responsive-map(xs 1.6rem, xl 2.4rem));

  align-items: center;
  display: flex;
  height: 2em;
}

.context-teaser__social-media-accounts {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.context-teaser__social-media-account {
  display: block;
  position: relative;
  z-index: 3;

  & + & {
    margin-left: 1.6rem;
  }
}
