.upload-field__uploads {
  margin-top: 1.2rem;
}

.upload-field__uploads-summary {
  font-weight: bold;
}

.upload-field__uploads-list {
  list-style-type: square;
  margin-top: 0.8rem;
  padding-left: 2rem;
}
