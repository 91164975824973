:root {
  --list-color: #{$color-blue-1};
  --list-font-size: 1.8rem;
}

.list {
  @include use-responsive-sizing(font-size, (
    xs: var(--list-font-size),
    m: calc(var(--list-font-size) * 1.11),
    l: calc(var(--list-font-size) * 1.22),
  ));

  color: var(--list-color);
  line-height: $line-height-body;
  padding-left: 2.4rem;

  > li + li {
    margin-top: 0.8rem;
  }

  > li * + * {
    margin-top: 0.8rem;
  }
}

.list--unordered {
  list-style-type: square;
}
