@import '@splidejs/splide/src/css/template/default/index';

.full-gallery {
  max-width: 100%;

  &__container {
    @include use-responsive-sizing(
      padding-top,
      responsive-map(xs 48px, l 80px)
    );

    max-width: 100%;
    display: flex;
    justify-content: center;
  }

  .panel-wrapper {
    background-color: #fff;
    display: flex;
    float: left;
    height: 68px;
    position: sticky;
    width: 100%;
    top: 0;
    z-index: z-index('full-gallery');
    margin-bottom: 16px;

    @include mq($from: m) {
      margin-bottom: 48px;
    }

    @include mq($from: l) {
      margin-bottom: 80px;
    }
  }

  .sticky-wrapper {
    align-items: center;
    background: transparent;
    display: flex;
    float: left;
    justify-content: center;
    position: relative;
    shape-outside: linear-gradient(transparent, transparent);
    top: 0;
    width: 100%;
    z-index: z-index('full-gallery');
  }

  &__navigation {
    display: flex;
    position: relative;
    padding: 16px 8px;
    overflow: auto;

    li {
      list-style: none;
      white-space: nowrap;
    }

    a {
      @include font-paragraph-small;
      color: $color-blue-1;
      font-weight: $font-weight-medium-500;
      line-height: 28px;
      margin: 0 1em;

      @include use-underline('&.is-active', '&:active', '&:hover');
    }
  }

  &__headline {
    @include use-container();

    padding-bottom: 32px;
    text-align: center;
  }

  &__slideshows {
    float: left;
    width: 100%;
  }

  &__description-content-wrapper {
    @include use-container();

    position: relative;
    display: flex;
    justify-content: space-between;

    @include mq($from: m) {
      height: 10vh;
    }
  }

  &__description-height-regulator {
    height: auto;

    @include mq($from: m) {
      height: 0;
    }
  }

  &__description-box {
    position: relative;
    width: 520px;
    max-width: 90vw;
    padding: 32px 0;
    pointer-events: none;
    transition: transform 0.5s 0s, background-color 0.2s 0.1s;

    @include mq($from: m) {
      padding: 32px;
    }

    &.is-open {
      @include mq($from: m) {
        background-color: $color-white;
        transform: translate(
          0,
          -99%
        ); // 100% - 1% to avoid unwanted background gaps
        height: auto;
        transition: transform 0.5s 0.2s, background-color 0.2s 0s;
      }
    }

    .page-content__heading {
      @include use-underline();
      max-width: calc(
        100% - 100px
      ); // give space for absolute slider controls width
      margin-top: 0;
      margin-bottom: 16px;
      font-weight: $font-weight-medium-500;
      position: relative;
      display: inline-block;
      transition: width 0.5s 0.1s;
      cursor: pointer;

      @include mq($from: m) {
        max-width: 100%;
        pointer-events: auto;
      }
    }

    &.is-open .page-content__heading {
      @include mq($from: m) {
        transition: width 0.5s;
        width: 100% !important;
      }
    }

    p {
      @include font-paragraph-small;
      opacity: 1;
      transition: opacity 0.2s;

      @include mq($from: m) {
        opacity: 0;
      }
    }

    &.is-open p {
      @include mq($from: m) {
        opacity: 1;
        transition: opacity 0.4s 0.3s;
      }
    }

    .icon {
      display: none;
      width: 16px;
      height: 26px;
      position: absolute;
      right: 0;
      transform: rotate(45deg);
      top: 0;
      transition: transform 0.5s 0.5s;

      @include mq($from: m) {
        display: block;
      }
    }

    &.is-open .icon {
      @include mq($from: m) {
        transform: rotate(0deg);
        transition: transform 0.5s 0.5s;
      }
    }
  }

  .image__img {
    width: auto; // required for safari
    height: 55vw;

    @include mq($from: m) {
      height: 58vw;
    }

    @include mq($from: xl) {
      height: calc(100vmin - 300px);
    }
  }

  .splide {
    @include use-responsive-sizing(
      padding-top,
      responsive-map(xs 40px, xl 72px)
    );

    &__list {
      padding-left: 24px;
      padding-right: 24px;
    }
  }

  &__controls {
    position: absolute;
    right: 24px;
    margin-top: 16px;
    white-space: nowrap;

    button {
      display: inline-block;
      font-size: 16px;
      padding: 16px;
      color: $color-blue-1;
    }

    button:nth-child(2) svg {
      transform: rotate(180deg);
    }
  }
}
