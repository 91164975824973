.pill-navigation {
  @include use-responsive-sizing(padding-top padding-bottom, responsive-map(xs 5.4rem, xl 7.2rem));
}

.pill-navigation__inner {
  @include use-container();
}

.pill-navigation__headline {
  margin-bottom: 4rem;
}
