.article-breaker {
  background-color: $color-blue-1;
  color: $color-white;
  position: relative;
  z-index: 1;
}

.article-breaker__image-container {
  display: flex;
  flex-direction: column;
  inset: 0;
  overflow: hidden;
  position: absolute;
  z-index: 1;
}

.article-breaker__image {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  transition-property: transform;

  .article-breaker:hover &,
  .article-breaker:focus-within & {
    transform: scale(1.05);
  }
}

.article-breaker__inner {
  @include use-container();
  @include use-responsive-sizing(height, responsive-map(xs 45rem, xl 80rem));
  @include use-responsive-sizing(row-gap, map-multiply($gaps, 1.5));
  @include use-responsive-sizing(padding-top padding-bottom, responsive-map(xs 5rem, xl 10rem));

  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-end;
  position: relative;
  z-index: 2;

  @include mq($from: m) {
    align-items: flex-end;
    flex-direction: row;
    justify-content: space-between;
  }

  .article-breaker--large & {
    @include use-responsive-sizing(height, responsive-map(xs 55rem, xl 92rem));
  }
}

.article-breaker__label {
  flex-basis: 100%;
  font-size: 1.6rem;
  font-weight: $font-weight-medium;
  margin-bottom: auto;
  text-shadow: 0 0 3px rgba($color-blue-1, 0.5);
  text-transform: uppercase;
}

.article-breaker__content {
  @include use-responsive-sizing(width, (
    s: column-width(s, 12),
    m: column-width(m, 8),
    l: column-width(l, 8),
    xl: column-width(xl, 8),
  ));
}

.article-breaker__calendar-sheet {
  --calendar-sheet-color: #{$color-white};
}

.article-breaker__headline {
  --headline-color: #{$color-white};

  &:not(:first-child) {
    margin-top: 3.2rem;
  }
}

.article-breaker__details {
  --meta-bar-color: #{$color-white};
  --meta-bar-hover-color: #{$color-white};

  &:not(:first-child) {
    margin-top: 2.4rem;
  }
}

.article-breaker__button {
  flex-shrink: 0;
}
