.anchor-link-list {
  @include use-container();
  @include use-responsive-sizing(
    padding-top padding-bottom,
    responsive-map(xs 10rem, xl 15rem)
  );

  display: block;

  .filter-items {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    transform: translateX(0);
    transition-duration: $duration-long;
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);

    .filter-item {
      display: flex;
      flex-direction: column;
      width: auto;

      &::after {
        content: '';
        display: block;
        width: 100%;
      }
    }

    .button {
      align-items: center;
      border-radius: 100px;
      display: flex;
      font-size: var(--category-filter-button-font-size, 16px);
      font-weight: 400;
      gap: 8px;
      justify-content: center;
      padding: 12px 24px;
    }

    .icon {
      width: 10px;
    }
  }
}
