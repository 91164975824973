.split-view {
  display: flex;
  flex-direction: column;

  @include mq($from: m) {
    height: 100vh;
  }
}

.split-view__columns {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;

  @include mq($from: m) {
    flex-direction: row;
  }
}

.split-view__image-with-logo {
  background-color: $color-blue-3;
  flex-grow: 2;
  flex-shrink: 0;
  min-height: 20rem;
  padding: 5vw;
  position: relative;

  @include mq($from: m) {
    flex-grow: 1;
    height: auto;
    max-width: 80rem;
    width: 50%;
  }
}

.split-view__logo {
  @include use-responsive-sizing(width, (
    xs: 18rem,
    m: 20rem,
    l: 26rem,
    xl: 28rem,
  ));

  position: relative;
  z-index: 2;
}

.split-view__headline {
  margin-top: 4rem;
  max-width: 60rem;
  position: relative;
  z-index: 2;

  .split-view--inverted & {
    --headline-color: #{$color-white};
  }
}

.split-view__image {
  display: flex;
  flex-direction: column;
  inset: 0;
  position: absolute;
  z-index: 1;
}

.split-view__inner {
  flex-grow: 3;
  overflow-x: auto;
  overflow-y: auto;

  @include mq($from: m) {
    flex-grow: 1;
    width: 50%;
  }
}

.split-view__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  max-width: calc(60rem + 10vw);
  min-height: 100%;
  padding: 5vw;

  > * + * {
    margin-top: 2rem;
  }

  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }
}

.split-view__horizontal-rule,
.split-view__aside {
  margin-bottom: 4rem;
  margin-top: 4rem;
}

.split-view__heading {
  margin-top: 3.2rem;

  + .split-view__paragraph {
    margin-top: 1.2rem;
  }
}

.split-view__horizontal-rule {
  margin-left: auto;
  margin-right: auto;
  width: 60%;
}

.split-view__footer {
  flex-shrink: 0;
}
