.pagination__inner {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  font-size: 1.6rem;
  justify-content: space-between;
  margin: -1.6rem -0.8rem;

  > * + * {
    padding: 1.6rem 0.8rem;
  }

  @include mq($from: m) {
    flex-wrap: nowrap;
    font-size: 2rem;
  }
}

.pagination__link {
  flex-shrink: 0;
}

.pagination__pages {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  order: -1;
  width: 100%;

  @include mq($from: m) {
    order: 0;
    width: auto;
  }
}

.pagination__page {
  @include use-responsive-sizing(margin-right, (xs: 0.8rem, m: 1.2rem));

  box-shadow: inset 0 0 0 1px $color-blue-1;
  color: $color-blue-1;
  display: inline-block;
  padding: 1.2rem;
  transition-property: background-color, color;

  &:hover,
  &:focus,
  &[aria-current='page'] {
    background-color: $color-blue-1;
    color: $color-white;
  }

  &[aria-current='page'] {
    font-weight: $font-weight-medium;
  }

  &:last-child {
    margin-right: 0;
  }
}
