$linesGraphicLineWidth: .5rem;
.lines-graphic {
    background-color: $color-blue-3;
    display: block;
    min-height: 20vh;
    overflow: hidden;
    padding: 8rem 0;
    position: relative;
    width: 100%;

    &--sources {
        padding-bottom: 0;
    }

    &__head {
        @include use-container();
        margin-bottom: 5rem;
    }

    &__headline {
        .headline__kicker {
            font-size: 1em;
            font-weight: $font-weight-regular;
            margin-bottom: 0;
        }
    }

    &__graphic {
        @include use-container();
        display: flex;
        flex-wrap: wrap;
        gap: 8rem 10%;
        position: relative;
    }

    &__single-point {
        color: $color-blue-1;
        display: flex;
        flex-direction: column;
        position: relative;
        &__head {
            align-items: center;
            display: flex;
            margin-bottom: 2rem;
            position: relative;
        }
        &__icon {
            color: $color-blue-2;
            font-size: 7rem;
            margin-right: 1.5rem;
            min-width: 7rem;
            position: relative;
            @include mq($from: l) {
                font-size: 9rem;
                margin-right: 1rem;
                min-width: 9rem;
            }
        }
        &__headline {
            font-weight: $font-weight-medium;
            position: relative;
            .headline__kicker {
                font-size: .95em;
                font-weight: $font-weight-regular;
                margin-bottom: 0;
            }
        }
        &__text {
            display: block;
            font-size: 2.4rem;
            line-height: 1.33em;
            position: relative;
        }
        &:first-child {
            .lines-graphic__single-point__head::before {
                background-color: $color-blue-2;
                bottom: calc(50% - 2rem);
                content: '';
                display: block;
                height: $linesGraphicLineWidth;
                left: calc(-50vw - 1rem);
                position: absolute;
                width: 50vw;
            }
        }
        &:not(:last-child) {
            &::after {
                background-color: $color-blue-2;
                bottom: -7rem;
                content: '';
                display: block;
                height: 5rem;
                left: calc(50% - $linesGraphicLineWidth/2);
                position: absolute;
                width: $linesGraphicLineWidth;
            }

            @include mq($from: l) {
                &::after {
                    display: none;
                }
            }
        }
        &:last-child {
            .lines-graphic__single-point__text::after {
                background-color: $color-blue-1;
                bottom: -9rem;
                content: '';
                display: block;
                height: 8rem;
                left: calc(50% - $linesGraphicLineWidth/2);
                position: absolute;
                width: $linesGraphicLineWidth;

                @include mq($from: l) {
                    background-color: transparent;
                    border: $linesGraphicLineWidth solid transparent;
                    border-bottom: $linesGraphicLineWidth solid $color-blue-1;
                    border-radius: 50%;
                    bottom: auto;
                    height: 15vw;
                    left: auto;
                    right: 0;
                    top: 100%;
                    transform: rotate(-135deg);
                    width: 15vw;
                }
            }
        }

        &--full {
            width: 100%;
            @include mq($from: l) {
                .lines-graphic__single-point__head .headline {
                    max-width: 40vw;
                }
                .lines-graphic__single-point__text {
                    max-width: 75%;
                }
            }
        }
        &--full,
        &--full + &--half + &--half + &--half {
            @include mq($from: l) {
                .lines-graphic__single-point__head .headline__text {
                    &::after {
                        background-color: transparent;
                        border: $linesGraphicLineWidth solid $color-blue-2;
                        border-radius: 6vw;
                        bottom: .3em;
                        clip-path: polygon(6vw calc(10vw - 4rem), 6vw 100%, 100% 100%, 100% calc(10vw - 4rem));
                        content: '';
                        display: block;
                        height: calc(12vw + 5rem);
                        max-width: calc($inner-container-max-width / 2);
                        left: calc(100% - 1em);
                        position: absolute;
                        width: calc(70vw - 100%);
                    }
                }
            }
        }
        // mirror elements
        &--full + %--half + &--full,
        &--full + &--full {
            @include mq($from: l) {
                align-items: flex-end;
                .lines-graphic__single-point__headline {
                    order: 1;
                    .headline__text {
                        &::after {
                            left: auto;
                            right: calc(100% - 1em);
                            transform: scale(-1,1);
                        }
                    }
                }
                .lines-graphic__single-point__icon {
                    margin-left: 1.5rem;
                    margin-right: 0;
                    order: 2;
                }
                &:last-child {
                    .lines-graphic__single-point__text::after {
                        left: -10vw;
                        right: auto;
                        transform: rotate(135deg);
                    }
                }
            }
        }
        // remirror to original
        &--full + &--full + &--full {
            @include mq($from: l) {
                align-items: flex-start;
                .lines-graphic__single-point__headline {
                    order: 2;
                    .headline__text {
                        &::after {
                            left: calc(100% - 1em);
                            right: auto;
                            transform: none;
                        }
                    }
                }
                .lines-graphic__single-point__icon {
                    order: 1;
                }
            }
        }
        &--half {
            width: 100%;
            @include mq($from: l) {
                width: 45%;
            }
        }
        &--half + &--half {
            @include mq($from: l) {
                .lines-graphic__single-point__head::after {
                    color: $color-blue-2;
                    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 104.85 125.74' fill='none' stroke='%230a5ae1' stroke-width='.3rem'%3E%3Cpath vector-effect='non-scaling-stroke' d='m59.05 5.5 2.53.05m-35.33 47.6H0m61.58-47.6h43.27'/%3E%3Cpath vector-effect='non-scaling-stroke' d='M61.58 5.55c-.66-.03-1.31-.05-1.98-.05h-.55c-9 .13-16.7 3.38-23.1 9.75-6.03 6.1-9.27 13.33-9.7 21.7v16.2h31.38c.66-.03 1.31-.05 1.97-.05s1.32.02 1.98.05c.09 0 .18.01.27.03 8.18.48 15.24 3.64 21.17 9.5.06.06.12.12.18.17C89.73 69.42 93 77.3 93 86.5s-3.27 17.1-9.8 23.6c-6.5 6.57-14.37 9.85-23.6 9.85s-17.12-3.28-23.65-9.85c-6.11-6.11-9.35-13.43-9.73-21.95-.02-.54-.02-1.09-.02-1.65 0-.66.02-1.31.05-1.95v-31.4m0-12.3c-.03-.64-.05-1.29-.05-1.95 0-.66.02-1.31.05-1.95m35.33 16.2h-3.95m-31.4 35 .03-3.6'/%3E%3C/svg%3E");
                    display: block;
                    height: auto;
                    position: absolute;
                    left: -15%;
                    top: 45%;
                    width: 10%;
                }
            }
        }
        
    }

    &__sources {
        background-color: $color-blue-2;
        color: $color-white;
        display: block;
        margin-top: 6rem;
        padding: 2rem 0;
        position: relative;
        width: 100%;
        &__inner {
            @include use-container();
            display: block;
            position: relative;
        }
    }

}