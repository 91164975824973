@keyframes header-show-navigation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.header {
  --header-navigation-button-color: #{$color-blue-1};
  --header-navigation-button-background-color: transparent;
  --header-navigation-button-hover-color: #{$color-white};
  --header-navigation-button-hover-background-color: #{$color-blue-1};

  padding-bottom: 1.4rem;
  padding-top: 1.4rem;
}

.header--inverted {
  --header-navigation-button-color: #{$color-white};
  --header-navigation-button-background-color: transparent;
  --header-navigation-button-hover-color: #{$color-blue-1};
  --header-navigation-button-hover-background-color: #{$color-white};
}

.header__masthead {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 4.4rem;
  justify-content: center;
}

.header__masthead--navigation {
  @include use-container();

  margin-top: 1.4rem;
  margin-bottom: 2rem;
}

.header__masthead-logo {
  width: 16.5rem;

  &:not(:last-child) {
    width: 14rem;
  }
}

.header__masthead-text {
  font-weight: $font-weight-medium;
  margin-top: 0.6rem;
  font-size: 1.4rem;
}

.no_display {
  display: none;
}

.header__button {
  background-color: var(--header-navigation-button-background-color);
  border-radius: 50%;
  color: var(--header-navigation-button-color);
  font-size: 2.4rem;
  height: 4.4rem;
  line-height: 0;
  margin-right: -1rem;
  padding: 1rem;
  transition-property: background-color, color;
  user-select: none;
  width: 4.4rem;

  &:hover,
  &:not(.has-invisible-focus):focus {
    background-color: var(--header-navigation-button-hover-background-color);
    color: var(--header-navigation-button-hover-color);
  }
}

.header__navigation {
  --header-navigation-button-color: #{$color-white};
  --header-navigation-button-hover-color: #{$color-blue-1};
  --header-navigation-button-hover-background-color: #{$color-white};
  --focus-outline-color: #{$color-white};

  animation: header-show-navigation $duration-default;
  background-color: $color-blue-1;
  color: $color-white;
  display: flex;
  flex-direction: column;
  inset: 0;
  position: fixed;
  z-index: 10;

  &:not(.header__navigation--open) {
    display: none;
  }
}

.header__navigation-logo {
  @include use-responsive-sizing(margin-left, (
    xs: map-get($inner-container-padding, xs),
    s: map-get($inner-container-padding, s),
  ));

  margin-bottom: 1.4rem;
  margin-top: 1.4rem;
}

.header__navigation-close {
  @include use-responsive-sizing(right, (
    xs: map-get($inner-container-padding, xs),
    s: map-get($inner-container-padding, s),
  ));

  position: absolute;
  top: 1.4rem;
}

.header__navigation-levels {
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
}

.header__navigation-1st-level {
  transition-property: transform;

  .header__navigation-levels[data-level='2'] & {
    transform: translateX(-100%);
  }

  .header__navigation-levels[data-level='3'] & {
    transform: translateX(-200%);
  }
}

.header__navigation-1st-level-item,
.header__navigation-2nd-level-item,
.header__navigation-3rd-level-item {
  display: block;
}

.header__navigation-2nd-level-job-search {
  @include use-responsive-sizing(padding-left padding-right, (
    xs: map-get($inner-container-padding, xs),
    s: map-get($inner-container-padding, s),
  ));

  margin-top: 4rem;
}

.header__navigation-2nd-level-back,
.header__navigation-3rd-level-back {
  --text-button-color: #{$color-white};
  --text-button-icon-color: #{$color-white};
  --text-button-hover-color: #{$color-white};
  --text-button-icon-hover-color: #{$color-white};
  --focus-outline-offset: -3px;

  @include use-responsive-sizing(padding-left padding-right, (
    xs: map-get($inner-container-padding, xs),
    s: map-get($inner-container-padding, s),
  ));

  display: block;
  min-height: 6rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
  text-align: left;
  width: 100%;
}

.header__navigation-1st-level-link,
.header__navigation-2nd-level-link,
.header__navigation-3rd-level-link {
  --focus-outline-offset: -3px;

  @include use-responsive-sizing(padding-left padding-right, (
    xs: map-get($inner-container-padding, xs),
    s: map-get($inner-container-padding, s),
  ));

  align-items: center;
  display: flex;
  font-size: 2.4rem;
  font-weight: $font-weight-medium;
  justify-content: space-between;
  min-height: 6rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
  text-align: left;
}

.header__navigation-1st-level-link-arrow,
.header__navigation-2nd-level-link-arrow,
.header__navigation-3rd-level-link-arrow {
  font-size: 4rem;
  line-height: 0;
}

.header__navigation-2nd-level,
.header__navigation-3rd-level {
  left: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}

.header__meta-navigation {
  @include use-responsive-sizing(padding-left padding-right, (
    xs: map-get($inner-container-padding, xs),
    s: map-get($inner-container-padding, s),
  ));

  background-color: rgba(#fff, 0.1);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 2.4rem;
  padding-top: 2.4rem;
}

.header__meta-navigation-item {
  --link-color: #{$color-white};
  --link-hover-color: #{$color-white};

  display: block;
  font-size: 1.4rem;
  padding: 1.6rem;
}

.header__meta-navigation-item--group-logo {
  --logo-height: 2.4rem;
  --logo-width: auto;

  flex-basis: 100%;
  text-align: center;
}

.header__meta-navigation-group-logo--desktop {
  display: none;
}

// Hide desktop elements
.header__navigation-indicator {
  display: none;
}
