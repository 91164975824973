@keyframes alert-banner-fade-in {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.alert-banner {
  --headline-color: #{$color-white};
  --text-button-color: #{$color-white};
  --text-button-icon-color: #{rgba($color-white, 0.5)};
  --text-button-hover-color: #{$color-white};
  --text-button-icon-hover-color: #{$color-white};

  animation-duration: $duration-default;
  animation-name: alert-banner-fade-in;
  background-color: $color-blue-1;
  border-top: 3px solid $color-white;
  bottom: 0;
  color: $color-white;
  position: sticky;
  z-index: z-index('alert-banner');
}

.alert-banner__inner {
  @include use-container();
  @include use-responsive-sizing(padding-top padding-bottom, responsive-map(xs 3.2rem, xl 4.8rem));
}

.alert-banner__content {
  position: relative;
}

.alert-banner__headline {
  padding-right: 5rem;

  @include mq($from: m) {
    max-width: 80%;
  }
}

.alert-banner__link {
  margin-top: 2.4rem;
  padding-bottom: 0.8rem;
}

.alert-banner__dismiss {
  --icon-button-background-color: #{$color-white};
  --icon-button-color: #{$color-blue-1};
  --icon-button-background-color-hover: #{$color-blue-3};
  --icon-button-color-hover: #{$color-blue-1};

  position: absolute;
  right: 0;
  top: 0.5rem;
}
