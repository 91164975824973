.quote-slider {
  --pager-button-color: #{$color-blue-1};
  --pager-button-background-color: #{$color-white};
  --pager-button-focus-color: #{$color-white};
  --pager-button-focus-background-color: #{$color-blue-1};
  --pager-background-color: rgba(255, 255, 255, 0.2);
  --pager-foreground-color: #{$color-white};

  .quote__quote {
    --paragraph-color: $color-white;
    @include font-paragraph-large;
  }

  .quote__caption {
    font-size: 2.2rem;
    font-weight: $font-weight-medium-500;
    line-height: 3.3rem;
  }

  .quote-block {
    &__inner {
      align-items: flex-start;
      justify-content: space-between;
    }

    &__image {
      margin-bottom: 4rem;
      margin-right: 0;
      margin-top: 2rem;
      max-width: 50%;
      width: 500px;

      @include mq($from: m) {
        margin-bottom: 0;
        max-width: calc(50% - 6rem);
      }

      .quote__icon {
        @include mq($from: m) {
          right: 0;
          top: -3rem;
        }

        @include mq($from: xl) {
          right: 0;
          top: -4rem;
        }
      }

      .quote__image {
        @include use-responsive-sizing(
                        margin-right,
                        $gaps,
                $start-breakpoint: m
        );
        max-width: 100%;
      }
    }

    &__quote {
      @include mq($from: m) {
        max-width: 50%;
      }
    }
  }
}

.quote-slider__inner {
  overflow: hidden;
  position: relative;
}

.quote-slider__headline {
  --headline-color: #{$color-white};

  @include use-container($max-width: $max-width-content-medium);

  align-items: center;
  display: flex;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  z-index: $z-index-extra-level-2;
  margin-top: 40px;
  margin-bottom: 40px;

  @include mq($from: m) {
    justify-content: center;
  }
  @include mq($from: l) {
    margin-top: 72px;
    margin-bottom: 72px;
  }
}

.quote-slider__slides {
  @include use-break-out();

  align-items: stretch;
  display: flex;
  position: relative;
  transform: translateX(0);
  transition-duration: $duration-long;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
  z-index: $z-index-foreground;
}

.quote-slider__slide {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
}

.quote-slider__navigation {
  // todo refactor this bad scss please :(
  @include use-responsive-sizing(
                  height,
                  responsive-map(xs 10rem, xl 15rem),
          $end-breakpoint: s,
          $scoped: true
  );

  position: absolute;
  z-index: $z-index-extra-level-2;
  left: 0;
  width: 13rem;
  margin-left: 0;
  align-items: center;
  display: flex;
  justify-content: center;
  bottom: 10px;

  @include mq($from: m) {
    left: 50%;
    margin-left: -7.5rem;
    width: 15rem;
    bottom: 50px;
  }

  @include mq($from: l) {
    left: 50%;
    margin-left: -7.5rem;
    width: 15rem;
    bottom: 80px;
  }
}

.quote-slider__navigation-pager {
  @include use-responsive-sizing(height, responsive-map(xs 10rem, xl 15rem));

  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  z-index: $z-index-extra-level-2;

  @include mq($until: m) {
    display: none;
  }
}

.quote-slider__navigation-button {
  color: $color-white;
  font-size: 3rem;
  z-index: $z-index-extra-level-2;

  &[disabled] {
    color: $color-ui-gray;
    cursor: not-allowed;
  }

  & + & {
    margin-left: 2.4rem;
  }

  @include mq($from: m) {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

.quote-slider__navigation-button--prev {
  left: 2.4rem;
}

.quote-slider__navigation-button--next {
  right: 2.4rem;
}
