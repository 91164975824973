:root {
  --headline-color: #{$color-blue-1};
}

.headline {
  --underline-offset: 0.17em;

  color: var(--headline-color);
  line-height: $line-height-headline;
}

.headline--1,
.headline--3 {
  --headline-highlight: #{$font-weight-medium};
  font-weight: $font-weight-light;
}

.headline--2,
.headline--4 {
  --headline-highlight: #{$font-weight-light};
  font-weight: $font-weight-medium;
}

.headline--1 {
  @include use-responsive-sizing(font-size, responsive-map(xs 3rem, xl 6rem));
}

.headline--2 {
  @include use-responsive-sizing(font-size, responsive-map(xs 2.6rem, xl 3.6rem));
}

.headline--3 {
  @include use-responsive-sizing(font-size, responsive-map(xs 2.6rem, xl 3.2rem));
}

.headline--4 {
  @include use-responsive-sizing(font-size, responsive-map(xs 2.4rem, xl 2.8rem));
}

.headline--5 {
  font-size: 2.2rem;
  font-weight: $font-weight-bold;
}

.headlight__hightlight {
  font-weight: var(--headline-highlight);
}

.headline__link {
  display: block;
}

.headline__kicker {
  color: var(--headline-kicker-color, currentColor);
  display: block;
  font-size: var(--headline-kicker-font-size, 1.6rem);
  font-weight: var(--headline-kicker-font-weight, bold);
  line-height: $line-height-body;
  margin-bottom: var(--headline-kicker-margin, 0.8rem);
  text-transform: none;
}
