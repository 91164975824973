:root {
  --dropdown-navigation-button-background-color: #{$color-blue-4};
  --dropdown-navigation-button-border-color: #{$color-blue-4};
  --dropdown-navigation-button-color: #{$color-blue-1};
  --dropdown-navigation-button-focus-background-color: #{$color-blue-1};
  --dropdown-navigation-button-focus-border-color: #{$color-blue-1};
  --dropdown-navigation-button-focus-color: #{$color-white};
  --dropdown-navigation-flyout-color: #{$color-white};
  --dropdown-navigation-flyout-icon-color: #{$color-white};
  --dropdown-navigation-flyout-hover-icon-color: #{$color-white};
  --dropdown-navigation-flyout-divider-color: #{$color-white};
  --dropdown-navigation-flyout-background-color: var(--dropdown-navigation-button-focus-background-color);
}

@keyframes show-dropdown-navigation-children {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.dropdown-navigation {
  z-index: 1;
}

.dropdown-navigation--outline {
  --dropdown-navigation-button-background-color: var(--theme-background-color);
  --dropdown-navigation-button-border-color: var(--theme-foreground-color);
  --dropdown-navigation-button-color: var(--theme-foreground-color);
  --dropdown-navigation-button-focus-background-color: var(--theme-foreground-color);
  --dropdown-navigation-button-focus-border-color: var(--theme-foreground-color);
  --dropdown-navigation-button-focus-color: var(--theme-background-color);
  --dropdown-navigation-flyout-background-color: var(--theme-foreground-color);
  --dropdown-navigation-flyout-color: var(--theme-background-color);
  --dropdown-navigation-flyout-icon-color: var(--theme-background-color);
  --dropdown-navigation-flyout-hover-icon-color: var(--theme-background-color);
  --dropdown-navigation-flyout-divider-color: var(--theme-background-color);
}

.dropdown-navigation__items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -1.6rem;
  text-align: left;
}

.dropdown-navigation__item {
  --button-background-color: var(--dropdown-navigation-button-background-color);
  --button-border-color: var(--dropdown-navigation-button-border-color);
  --button-color: var(--dropdown-navigation-button-color);
  --button-focus-background-color: var(--dropdown-navigation-button-focus-background-color);
  --button-focus-border-color: var(--dropdown-navigation-button-focus-border-color);
  --button-focus-color: var(--dropdown-navigation-button-focus-color);
  --text-button-icon-opacity: 0.4;
  --text-button-icon-hover-opacity: 0.8;

  display: block;
  padding: 1.6rem;
  position: relative;
}

.dropdown-navigation--outline .dropdown-navigation__item--active {
  --button-focus-background-color: var(--dropdown-navigation-button-background-color);
  --button-focus-border-color: var(--dropdown-navigation-button-border-color);
  --button-focus-color: var(--dropdown-navigation-button-color);
  --button-background-color: var(--dropdown-navigation-button-focus-background-color);
  --button-border-color: var(--dropdown-navigation-button-focus-border-color);
  --button-color: var(--dropdown-navigation-button-focus-color);
}

.dropdown-navigation__children {
  animation: show-dropdown-navigation-children $duration-default;
  background-color: var(--dropdown-navigation-flyout-background-color);
  border-radius: 0.5rem;
  color: var(--dropdown-navigation-flyout-color);
  left: 50%;
  position: absolute;
  top: calc(100% + 2rem);
  transform: translateX(-50%);
  width: 30rem;
  z-index: z-index('dropdown');

  &::before {
    border-left: 1rem solid transparent;
    border-right: 1rem solid transparent;
    content: '';
    height: 0;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 0;
  }

  &[data-popper-placement='bottom']::before {
    border-bottom: 1rem solid var(--dropdown-navigation-flyout-background-color);
    top: -1rem;
  }

  &[data-popper-placement='top']::before {
    border-top: 1rem solid var(--dropdown-navigation-flyout-background-color);
    top: 100%;
  }
}

.dropdown-navigation__child {
  --text-button-color: var(--dropdown-navigation-flyout-color);
  --text-button-icon-color: var(--dropdown-navigation-flyout-icon-color);
  --text-button-hover-color: var(--dropdown-navigation-flyout-color);
  --text-button-icon-hover-color: var(--dropdown-navigation-flyout-hover-icon-color);

  display: block;

  & + &::before {
    background-color: var(--dropdown-navigation-flyout-divider-color);
    content: '';
    opacity: var(--dropdown-navigation-flyout-divider-opacity, 0.2);
    display: block;
    height: 1px;
  }
}

.dropdown-navigation__child-link {
  align-items: flex-start;
  display: flex;
  padding: 1.6rem 2.4rem;
}
