.event-teaser {
  color: $color-blue-1;
  padding: var(--teaser-padding);
  padding-bottom: 2rem;
  padding-top: 2rem;
}

.event-teaser__main-content {
  display: flex;
}

.event-teaser__header {
  display: flex;
  flex-direction: column;

  @include mq($from: m) {
    flex-direction: row;
  }
}

.event-teaser__calendar-sheet {
  flex-shrink: 0;
  //margin-bottom: 2.4rem;
  margin-right: 3.2rem;
}

.event-teaser__headline {
  margin-bottom: 1rem;
}

.event-teaser__details {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.event-teaser__footer {
  border-top: 1px solid $color-blue-1;
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  padding-top: 2rem;
}

.event-teaser__tags {
  align-self: center;
  display: flex;
  flex-wrap: wrap;
  margin: -0.6rem -0.8rem;
  padding-right: 1.6rem;
}

.event-teaser__tag {
  display: block;
  padding: 0.6rem 0.8rem;
}

.event-teaser__arrow {
  @include use-responsive-sizing(width height, responsive-map(xs 3.2rem, xl 5.4rem));
  @include use-responsive-sizing(font-size, responsive-map(xs 1.6rem, xl 2.4rem));

  align-items: center;
  background-color: $color-blue-2;
  border-radius: 50%;
  color: $color-white;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  line-height: 0;
  margin-left: auto;
}
