:root {
  --quote-flex-align: center;
  --quote-text-align: center;
  --quote-color: #{$color-blue-1};
  --quote-icon-color: #{$color-blue-2};
}

.quote {
  align-items: var(--quote-flex-align);
  color: var(--quote-color);
  display: flex;
  flex-direction: column;
  line-height: $line-height-body;
  text-align: var(--quote-text-align);
}

.quote__icon {
  @include use-responsive-sizing(font-size, responsive-map(xs 3.2rem, xl 5.6rem));

  color: var(--quote-icon-color);
  line-height: 0;
  margin-bottom: 1.2rem;
}

.quote__quote {
  @include use-responsive-sizing(font-size, responsive-map(xs 2.4rem, xl 4rem));
}

.quote__caption {
  @include use-responsive-sizing(font-size, responsive-map(xs 1.4rem, xl 2.2rem));

  font-weight: $font-weight-medium;
  margin-top: 2.4rem;
}
