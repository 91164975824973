@import "swiper/swiper-bundle.min.css";

.category-filter {
  overflow: hidden;
  width: 100%;

  .filter-items__scroller {
    @include use-break-out();

    overflow: hidden;

    @include mq($from: m) {
      margin: auto;
    }
  }

  .filter-items {
    align-items: flex-start;
    display: flex;
    padding-left: 16px;
    //margin-right: 16px;
    gap: 16px;
    transform: translateX(0);
    transition-duration: $duration-long;
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);

    @include mq($from: m) {
      flex-wrap: wrap;
      padding-left: 0;
      margin-right: 0;
    }

    .filter-item {
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      text-align: center;
      width: auto;
      max-width: 100%;

      @include mq($from: m) {
        width: auto;
      }
    }

    .button {
      align-items: center;
      border-radius: 100px;
      display: flex;
      font-size: var(--category-filter-button-font-size, 16px);
      font-weight: 400;
      gap: 8px;
      justify-content: center;
      padding: 0 48px;

      @include mq($from: m) {
        padding: 0 24px;
      }
    }
  }

  .main-categories,
  .sub-categories {
    margin-left: auto;
    margin-right: auto;
    max-width: 1444px;
    position: relative;
    width: 100%;
  }

  .sub-categories__headline {
    padding-top: var(--page-content-section-gap);
  }

  .teaser-grid__inner:first-child {
    padding-bottom: var(--page-content-section-gap);
    padding-top: 1em;
  }

  .filter-items__navigation-button {
    background-color: $color-white;
    border-radius: 50%;
    color: $color-blue-1;
    font-size: 16px;
    height: 32px;
    line-height: 0;
    padding: 8px;
    position: absolute;
    top: 11px; // 54px (item) - 32px (button) / 2
    transition-property: background-color, color;
    width: 32px;

    &[disabled] {
      color: $color-ui-gray;
      cursor: not-allowed;
      display: none;
    }

    &:not([disabled]):hover,
    &:not(.has-invisible-focus):focus {
      background-color: $color-blue-1;
      color: $color-white;
    }

    @include mq($from: m) {
      display: none;
    }
  }

  .filter-items__navigation-button--prev {
    left: 0;
  }

  .filter-items__navigation-button--next {
    right: 0;
  }

  .custom-button-next,
  .custom-button-prev {
    z-index: 10;
  }

  .loader {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
    display: none;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .infobox {
    background-color: transparent;

    .infobox__content {
      width: 100%;
    }
  }

  .additional-padding {
    padding-top: var(--page-content-section-gap);
  }

  .hidden-sm {
    display: none;

    @include mq($from: m) {
      display: flex;
    }
  }

  .hidden-md {
    display: flex;

    @include mq($from: m) {
      display: none;
    }
  }
}
