.quote-block {
  background-color: $color-blue-2;
  color: $color-white;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 100px 0 120px;

  @include mq($from: l) {
    padding: 180px 0 150px;
  }
}

.quote-block__inner {
  @include use-container($max-width: $max-width-content-medium);

  align-items: center;
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  justify-content: center;
}

.quote-block__image {
  @include use-responsive-sizing(
    margin-bottom,
    map-multiply($gaps, 1.5),
    $end-breakpoint: s,
    $scoped: true
  );
  @include use-responsive-sizing(margin-right, $gaps, $start-breakpoint: m);
  @include use-responsive-sizing(
    width,
    (
      xs: column-width(xs, 6),
      s: column-width(s, 6),
      m: column-width(m, 4),
      l: column-width(l, 4),
      xl: column-width(xl, 4),
    )
  );

  position: relative;

  .quote__icon {
    @include use-responsive-sizing(font-size, responsive-map(xs 4rem, xl 8rem));
    @include use-responsive-sizing(
      top right,
      responsive-map(xs -2rem, xl -4rem)
    );

    position: absolute;
    z-index: $z-index-foreground;
    top: -4rem;
    right: -4rem;
    font-size: 8rem;
    color: $color-white;
  }
}

.quote-block__quote {
  --quote-color: currentColor;
  --quote-icon-color: currentColor;

  .link {
    color: currentColor;
  }
  .u-underline {
    -webkit-text-decoration-color: currentColor;
    text-decoration-color: currentColor;
  }

  .quote {
    padding: 0;
  }

  @include use-responsive-sizing(
    width,
    (
      xs: column-width(xs, 12),
      s: column-width(s, 12),
      m: column-width(m, 8),
      l: column-width(l, 6),
      xl: column-width(xl, 8),
    )
  );
}

.quote-block__quote:not(:only-child) {
  @include mq($from: m) {
    --quote-flex-align: flex-end;
    --quote-text-align: right;
  }
}
