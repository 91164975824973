.header {
  --link-color: #{$color-blue-1};
  --link-hover-color: #{$color-blue-2};

  color: $color-blue-1;
  position: relative;
  z-index: z-index('header');
}

.header--inverted {
  --link-color: #{$color-white};
  --link-hover-color: #{$color-white};

  color: $color-white;
}

.header__inner {
  @include use-container();

  align-items: center;
  display: flex;
  justify-content: space-between;
}

@include mq($until: m) {
  @import 'header.mobile';
}

@include mq($from: m) {
  @import 'header.desktop';
}

.no_display {
  display: none;
}
