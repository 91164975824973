@use 'sass:math';

// Colors
$color-black: #000;
$color-white: #fff;
$color-blue-1: #000064;
$color-blue-2: #0a5ae1;
$color-blue-3: #d4f2f5;
$color-blue-4: #f2fafc;
$color-violet: #b3b2d1;
$color-orange: #ff5a46;
$color-red: #eb1700;
$color-gray: #a0a0a0;
$color-gray-2: #ccc;
$color-ui-gray: #767676;
$color-green: #146400;
$color-yellow: #edc739;

$color-font: $color-blue-1;

// Root
$root-color: $color-black;
$root-font-size-px: 10px;
$root-min-width: 320px;

$z-index-background: -1; // background elements
$z-index-default: 0; // reset z-index
$z-index-foreground: 1; // foreground elements
$z-index-extra-level-1: 10; // foreground elements
$z-index-extra-level-2: 20; // foreground elements
$z-index-extra-level-3: 30; // foreground elements
$z-index-extra-level-4: 40; // foreground elements
$z-index-extra-level-5: 50; // foreground elements
$z-index-extra-level-6: 60; // foreground elements
$z-index-extra-level-7: 70; // foreground elements
$z-index-extra-level-8: 80; // foreground elements
$z-index-extra-level-9: 90; // foreground elements
$z-index-base: 100; // used by z-index @function
$z-index-upper: 200; // above z-index-listed elements
$z-index-overlay: 999; // overlay above the most elements
$z-index-max: 9999; // above everything

// Body
$body-background-color: $color-white;
$body-font-size: 1.6rem;

// Link
$link-color: $color-blue-2;
$link-hover-color: $color-blue-1;

// Skip link
$skip-link-background-color: $color-black;
$skip-link-color: $color-white;

// Borders
$border-boxes: 2px solid $color-blue-3;

// Font families
$font-stack-body: 'ATRUVIA', Helvetica, Arial, sans-serif;
$font-stack-root: $font-stack-body;

// Font weights
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 600;

// Line height
$line-height-headline: 1.2;
$line-height-body: 1.5;

// Durations
$duration-default: 0.2s;
$duration-long: 0.4s;

// Z-Index
$z-index-list: 'modal', 'alert-banner', 'skip-link', 'dropdown', 'header',
  'page-header', 'full-gallery';

// Focus
$focus-outline-color: $color-black;

// mq()
$mq-responsive: true;

// Columns
$columns: 12;

// Gap
$gaps: (
  xs: 2.4rem,
  s: 2.4rem,
  m: 3.2rem,
  l: 3.6rem,
  xl: 4rem,
);

// stylelint-disable length-zero-no-unit
$mq-breakpoints: (
  xs: 0px,
  s: 600px,
  m: 768px,
  l: 1024px,
  xl: 1440px,
);
// stylelint-enable

// Inner container
$max-width-content-small: 800px;
$max-width-content-medium: 1140px;
$max-width-content-max: 1440px;

$max-width-text-desktop: $max-width-content-small;
$max-width-text-mobile: 600px;

$inner-container-padding: (
  xs: 5.5vw,
  s: 5.5vw,
  m: 5vw,
  l: 4vw,
  xl: 3vw,
);

$inner-container-max-width: $max-width-content-max;
$inner-container-max-padding: math.div(
    map-get($inner-container-padding, xl),
    100vw
  ) * $inner-container-max-width;

// Exports
:export {
  l: map-get($mq-breakpoints, l);
  m: map-get($mq-breakpoints, m);
  s: map-get($mq-breakpoints, s);
  xl: map-get($mq-breakpoints, xl);
  xs: map-get($mq-breakpoints, xs);
}
