.page {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.page__header--overlay {
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: z-index('header');
}

.page__header--gradient {
  &::before {
    background-image: linear-gradient(to bottom, rgba($color-blue-1, 0.7), cubic-bezier(0.48, 0.3, 0.64, 1), transparent);
    content: '';
    height: 20rem;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.page__footer {
  margin-top: auto;
}
