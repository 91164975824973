@import "glightbox/dist/css/glightbox.min.css";

$columnGaps: (
  xs: 1rem,
  s: 1rem,
  m: 1rem,
  l: 1rem,
  xl: 1rem,
);

.video-collection {
  @include use-responsive-sizing(padding-top padding-bottom, responsive-map(xs 4rem, xl 7.2rem));
  @include use-responsive-sizing(padding-left padding-right, responsive-map(xs 2rem, xl 6rem));
  --headline-color: #{$color-white};
  color: $color-white;
  background-color: $color-blue-1;
  margin-bottom: 11em;

  &__container {
    @include use-container();

    @include mq($from: s) {
      text-align: center;
    }

    @include mq($from: m) {
      overflow: auto;
    }

    overflow-x: scroll;

    &.video-collection__grids-2 {
      @include mq($from: m) {
        height: 55rem;
      }

      @include mq($from: l) {
        height: 67rem;
      }

      @include mq($from: xl) {
        height: 108rem;
      }

      .image {
        //width: 35vw;
      }

      overflow-x: auto;
    }

    &.video-collection__grids-3 {
      @include mq($from: m) {
        height: 55rem;
      }

      @include mq($from: l) {
        height: 55rem;
      }

      @include mq($from: xl) {
        height: 75rem;
      }

      .image {
        //width: 23vw;
      }
    }

    &.video-collection__grids-4 {
      //@include mq($from: m) {
      //  height: 22rem;
      //}

      //@include mq($from: l) {
      //  height: 55rem;
      //}
      //
      //@include mq($from: xl) {
      //  height: 60rem;
      //}
      //
      //.image {
      //  width: 17vw;
      //}
    }
  }

  &__subline {
    @include use-responsive-sizing(font-size, responsive-map(xs 1.6rem, xl 2.2rem));
    line-height: $line-height-body;
    margin-bottom: 3rem;
    margin-top: 1.6rem;

    @include mq($from: s) {
      margin-bottom: 6rem;
    }
  }

  &__items {
    @include use-responsive-sizing(column-gap, $columnGaps);

    @include mq($from: s) {
      @include use-responsive-sizing(row-gap, map-multiply($gaps, 1.5));
    }

    display: grid;
    grid-auto-rows: auto;
    grid-template-columns: repeat(var(--teaser-grid-columns), 1fr);
  }

  &__grid {
    &-2 {
      @include use-responsive-sizing(--teaser-grid-columns, (xs: 2, s: 2));
    }

    &-3 {
      @include use-responsive-sizing(--teaser-grid-columns, (xs: 3, s: 3));
    }

    &-4 {
      @include use-responsive-sizing(--teaser-grid-columns, (xs: 4, s: 4));
    }
  }

  &__item {
    align-items: start;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 3rem 0;
    overflow: hidden;
    position: relative;
    z-index: 1;

    @include mq($from: s) {
      align-items: start;
      border: 0;
      flex-direction: column;
    }

    .image {
      height: 100%;
      transition-duration: 0.2s;
      transition-property: transform;
      //width: 30rem;
      min-width: 30rem;

      @include mq($from: s) {
        max-width: none;
      }

      @include mq($from: xl) {
        //width: 35vw;
      }

      img {
        height: 100%;
        object-fit: cover;
      }
    }

    &:hover .image,
    &:focus-within .image {
      transform: scale(1.05);
    }

    //.figure {
    //  padding-left: 2rem;
    //}

    .figure,
    .figure__figure {
      aspect-ratio: auto 3/4;
    }

    @include mq($from: s) {
      .figure {
        padding-left: 0;
      }

      .figure,
      .figure__figure {
        aspect-ratio: auto 3/4;
      }
    }

    &-title {
      @include use-responsive-sizing(font-size, responsive-map(xs 1.6rem, xl 2.2rem));

      -moz-hyphens: auto;
      -o-hyphens: auto;
      -webkit-hyphens: auto;
      -ms-hyphens: auto;
      hyphens: auto;
      overflow-wrap: break-word;

      color: $color-white;
      line-height: $line-height-body;
      margin-right: auto;
      text-align: left;
      z-index: 1;

      @include mq($from: m) {
        color: $color-blue-1;
        margin-right: 0;
        margin-top: 1.6rem;
      }
    }
  }

  &__bg-white {
    background-color: $color-white;
    position: relative;
    z-index: 0;

    @include mq($from: m) {
      height: 3rem;
    }

    @include mq($from: l) {
      height: 10rem;
    }
  }

  &__headline {
    text-align: center;

    @include use-responsive-sizing(font-size, responsive-map(xs 2.2rem, xl 3.2rem));
    font-weight: 700;
    line-height: $line-height-headline;
    margin-bottom: 1.6rem;
    margin-top: 0;

    @include mq($from: s) {
      margin-bottom: 3rem;
    }
  }

  &__item-title {
    color: $color-white;
    padding-left: 2rem;
    padding-top: 2rem;
  }

  .video-collection__item {
    &:hover {
      .video-collection__item-play-icon {
        border-radius: 10%;

        svg {
          transform: rotate(360deg);
        }
      }
    }

    .video-collection__item-play-icon {
      background-color: $color-blue-2;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      display: flex;
      margin-top: -50px;
      bottom: 25px;
      position: relative;
      margin-left: 25px;
      align-items: center;
      justify-content: center;
      transition: .1s all ease-in;

      svg {
        width: 25px;
        height: auto;
        transition: .2s all ease-in-out;
      }
    }
  }

  .video-collection__button {
    margin-top: 2rem;

    @include mq($from: m) {
      text-align: center;
    }
  }

  .video-collection__backdrop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }
}

.gvideo-wrapper {
  padding: 2rem;
  background-color: $color-white;
}

.gdesc-inner {
  color: $color-blue-1;

  .gslide-title {
    color: $color-blue-1;
  }
}
