.password-area {
  position: relative;
  background-color: $color-blue-3;
  min-height: 650px;
  height: 100vh;
  transition: opacity 0.5s ease 0.4s;

  &.checkout {
    opacity: 0;
  }

  * {
    user-select: none;
  }

  &__appendix {
    top: -2.4rem;
    @include use-responsive-sizing(
      margin-bottom,
      (
        xs: -6.4rem,
        s: -8rem,
        m: -8.6rem,
        l: -9.2rem,
        xl: -10.4rem,
      )
    );

    &:before {
      transition: background-color 0.7s ease;
      background-color: $color-blue-3 !important;
    }

    &.step2::before {
      background-color: $color-blue-2 !important;
    }
  }

  &__form {
    display: grid;
    gap: 32px;
  }

  &__links {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5rem 0;
    transition: filter 0.7s ease, transform 0.7s ease, opacity 0.7s ease;

    &:nth-of-type(1).active {
      transform: translateY(100px);
    }

    &:nth-of-type(2).active {
      transform: translateY(-100px);
    }

    &.inactive {
      opacity: 0;
    }

    span {
      opacity: 0;
      margin-top: 2rem;
      white-space: nowrap;
      transition: opacity 0.7s ease 2.5s;
    }
  }

  @media (pointer: fine) {
    &__links:hover &__link {
      filter: blur(4px);
    }
  }

  &__links:hover &__link:hover {
    filter: blur(0);
  }

  .fade-in &__link span {
    opacity: 1;
  }

  &__house-upper {
    transform: translateY(78px);
    transition: transform 0.7s ease-in-out;
  }

  .fade-in &__house-upper {
    transform: translateY(0);

    path {
      transition: fill 0.7s ease;
      fill: $color-white !important;
    }
  }

  &__house-lower {
    transform: translateY(-78px);
    transition: transform 0.7s ease-in-out;
  }

  .fade-in &__house-lower {
    transform: translateY(0);

    path {
      transition: fill 0.7s ease;
      fill: $color-white !important;
    }
  }

  &__step1-headline {
    opacity: 1;
    transition: opacity 0.7s ease;
  }

  .fade-out &__step1-headline {
    opacity: 0;
  }

  &__input {
    position: relative;
    margin: auto;
    width: 100%;
    max-width: 500px;
    opacity: 1;
    transition: opacity 0.7s ease;

    input.password {
      align-items: flex-start;
      align-self: stretch;
      background-color: transparent;
      border: 2px solid $color-blue-1;
      display: flex;
      padding: 16px;
      padding-right: 48px;
      width: 100%;
    }

    input.error {
      border: 2px solid $color-red;
      color: $color-red;
      font-weight: bold;
      outline-color: $color-red;
    }
  }

  .fade-out &__input {
    opacity: 0;
  }

  &__key {
    width: 247px;
    margin: auto;
    transform: rotateZ(90deg) scale(0.6);
    transition: transform 0.7s ease-in-out;
  }

  .fade-out &__key {
    transform: translateY(160px) translateX(-2px) scale(1) rotateZ(0deg);
  }

  &__step1 {
    display: none;
    position: absolute;
    z-index: 1;
    top: 50%;
    width: 100%;
    padding: 0 16px;
    transform: translateY(-50%);
    color: $color-blue-1;

    &.visible {
      display: block;
    }
  }

  &__step2 {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    color: $color-blue-1;
    background-color: $color-blue-3;
    transition: color 0.7s ease, background-color 0.7s ease;

    &.visible {
      display: block;
    }

    &.fade-in {
      background-color: $color-blue-2;
      color: $color-white;
    }
  }

  &__step2-headline {
    opacity: 0;
    position: absolute;
    max-width: 600px;
    top: calc(50% - 3rem);
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .fade-in &__step2-headline {
    animation: step2-headline 2s;
  }
  @keyframes step2-headline {
    0%,
    20% {
      opacity: 0;
    }
    30% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  .headline {
    font-weight: 700;
    text-align: center;
    color: inherit;
  }

  &__button {
    margin: 16px auto 96px;
    opacity: 1;
    transition: opacity 0.7s ease;
  }

  .fade-out &__button {
    opacity: 0;
  }

  .eyecon {
    cursor: pointer;
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);

    &:not(.visible) {
      display: none;
    }
  }
}
