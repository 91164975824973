.stage {
  --pager-button-color: #{$color-blue-1};
  --pager-button-background-color: #{$color-white};
  --pager-button-focus-color: #{$color-white};
  --pager-button-focus-background-color: #{$color-blue-1};
}

.stage--inverted {
  --pager-background-color: rgba(255, 255, 255, 0.2);
  --pager-foreground-color: #{$color-white};
}

.stage__inner {
  overflow: hidden;
  position: relative;
}

.stage__slides {
  align-items: stretch;
  display: flex;
  position: relative;
  transform: translateX(0);
  transition-duration: $duration-long;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
  z-index: 1;
}

.stage__slide {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
}

.stage__navigation {
  @include use-container();
  @include use-responsive-sizing(height, responsive-map(xs 10rem, xl 15rem));

  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  z-index: 2;
}

.stage__navigation--right {
  @include use-responsive-sizing(justify-content, (xs: center, m: flex-end));
}

.stage__navigation--left {
  @include use-responsive-sizing(justify-content, (xs: center, m: flex-start));
}

.stage__navigation-pager {
  border-right: 1px solid var(--pager-background-color);
  display: none;
  margin-right: 3.2rem;
  padding-right: 3.2rem;

  @include mq($from: m) {
    display: block;
  }
}

.stage__navigation-button {
  background-color: $color-white;
  border-radius: 50%;
  color: $color-blue-1;
  font-size: 1.6rem;
  height: 3.6rem;
  line-height: 0;
  padding: 1rem;
  transition-property: background-color, color;
  width: 3.6rem;

  &[disabled] {
    color: $color-ui-gray;
    cursor: not-allowed;
  }

  &:not([disabled]):hover,
  &:not(.has-invisible-focus):focus {
    background-color: $color-blue-1;
    color: $color-white;
  }

  & + & {
    margin-left: 1.6rem;
  }
}
