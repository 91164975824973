@keyframes header-show-flyout {
  0% {
    opacity: 0;
    transform: translateY(-0.5rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.header__inner {
  @include use-responsive-sizing(height, (
    m: 10rem,
    l: 12.5rem,
    xl: 13.5rem,
  ));

  @include mq($from: m, $until: l) {
    padding-top: 0.8rem;
  }
}

.header__masthead {
  display: flex;
  flex-shrink: 0;
  align-items: flex-end;

  @include mq($from: l) {
    padding-top: 0.6rem;
  }
}

.header__masthead-logo {
  @include use-responsive-sizing(width, (
    m: 20rem,
    l: 26rem,
    xl: 28rem,
  ));

  flex-shrink: 0;

  &:not(:last-child) {
    @include use-responsive-sizing(width, (
      m: 14rem,
      l: 22rem,
      xl: 24rem,
    ));
  }
}

.header__masthead-text {
  @include use-responsive-sizing(margin-left, (
    m: 2rem,
    l: 2.2rem,
    xl: 2.4rem,
  ));

  font-size: 2rem;
  font-weight: $font-weight-medium;
  line-height: 1;
}

.header__navigation {
  @include use-responsive-sizing(padding-bottom, (
    m: 1.6rem,
    l: 1.4rem,
    xl: 2rem,
  ));

  align-items: flex-end;
  display: flex;
  flex-direction: column;
}

.header__navigation-1st-level {
  display: flex;
}

.header__navigation-1st-level-item {
  @include use-responsive-sizing(margin-right, (
    m: 1.6rem,
    l: 4.8rem,
    xl: 6rem,
  ));

  display: block;

  &:last-child {
    margin-right: 0;
  }
}

.header__navigation-1st-level-link {
  --underline-focus-color: transparent;

  display: block;
  font-size: 2rem;
  font-weight: $font-weight-medium;
}

.header__navigation-2nd-level {
  --focus-outline-color: #{$color-white};

  @include use-container();
  @include use-responsive-sizing(padding-top padding-bottom, (
    m: 3.2rem,
    l: 4rem,
  ));

  animation: header-show-flyout $duration-default;
  background-color: $color-blue-1;
  display: none;
  left: 0;
  line-height: $line-height-body;
  margin-top: 2rem;
  position: absolute;
  right: 0;

  &:not([hidden]) {
    display: flex;
    flex-wrap: wrap;
  }
}

.header__navigation-2nd-level-item,
.header__navigation-2nd-level-item-group {
  display: block;
  flex-basis: 33.33%;
  flex-shrink: 1;
}

.header__navigation-2nd-level-item--overview {
  border-bottom: 2px solid rgba($color-white, 0.2);
  flex-basis: 100%;
  margin-bottom: 4rem;
  padding-bottom: 1.6rem;
}

.header__navigation-2nd-level-link,
.header__navigation-3rd-level-link {
  color: $color-white;
  display: block;
  padding-bottom: 0.4rem;
  padding-top: 0.4rem;
}

.header__navigation-2nd-level-link {
  @include use-responsive-sizing(font-size, (
    m: 2rem,
    l: 2.2rem,
    xl: 2.4rem,
  ));

  font-weight: $font-weight-bold;

  .header__navigation-2nd-level-item-group & {
    font-weight: inherit;
  }

  .header__navigation-2nd-level-item--overview & {
    @include use-responsive-sizing(font-size, (
      m: 2.2rem,
      l: 2.4rem,
      xl: 2.6rem,
    ));
  }
}

.header__navigation-2nd-level-job-search {
  margin-top: 3.2rem;
}

.header__navigation-3rd-level-item {
  display: block;

  & + & {
    margin-top: 0.8rem;
  }
}

.header__navigation-3rd-level-link,
.header__navigation-2nd-level-item-group .header__navigation-2nd-level-link {
  @include use-responsive-sizing(font-size, (
    m: 1.6rem,
    l: 1.8rem,
    xl: 2rem,
  ));
}

.header__navigation-indicator {
  background-color: currentColor;
  height: 0.4rem;
  margin-top: 1.6rem;
  opacity: 0;
  position: absolute;
  transform: translateX(0) scaleX(0);
  transform-origin: 0 0;
  transition-property: transform, opacity;
  width: 0.1rem;
}

.header__meta-navigation {
  @include use-responsive-sizing(margin-bottom, (
    m: 1.8rem,
    l: 3rem,
    xl: 3.8rem,
  ));

  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-top: -1.4rem;
  order: -1;

  @include mq($from: l) {
    margin-top: -0.8rem;
  }
}

.header__meta-navigation-item {
  display: block;
  font-size: 1.4rem;
  margin-right: 2.4rem;

  &:last-child {
    margin-right: 0;
  }
}

.header__meta-navigation-item--group-logo {
  --logo-width: auto;

  @include use-responsive-sizing(--logo-height, (
    m: 1.6rem,
    l: 1.8rem,
    xl: 2rem,
  ));

  border-left: 1px solid currentColor;
  padding-left: 2.4rem;
}

.header__meta-navigation-group-logo--mobile {
  display: none;
}

// Hide mobile elements
.header__menu-button,
.header__masthead--navigation,
.header__navigation-close,
.header__navigation-1st-level-link-arrow,
.header__navigation-2nd-level-link-arrow,
.header__navigation-2nd-level-item--back,
.header__navigation-3rd-level-item--back,
.header__navigation-3rd-level-item--overview {
  display: none;
}
