:root {
  --meta-bar-color: #{$color-blue-1};
  --meta-bar-hover-color: #{$color-blue-2};
}

.meta-bar {
  color: var(--meta-bar-color);
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem 1.6rem;
}

.meta-bar__item {
  display: flex;
  position: relative;
}

.meta-bar__label {
  font-size: 2rem;
  line-height: 0;
  margin-right: 0.8rem;
}

.meta-bar__value {
  --link-color: var(--meta-bar-color);
  --link-hover-color: var(--meta-bar-hover-color);

  align-self: center;
  font-size: 1.6rem;
}
