:root {
  --paragraph-color: #{$color-blue-1};
  --paragraph-font-size: 1.8rem;
}

.paragraph {
  @include use-responsive-sizing(font-size, (
    xs: var(--paragraph-font-size),
    m: calc(var(--paragraph-font-size) * 1.11),
    l: calc(var(--paragraph-font-size) * 1.22),
  ));

  color: var(--paragraph-color);
  line-height: $line-height-body;
}
