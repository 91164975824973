.elc-cookie-consent {
    background-color: $color-blue-4;
    bottom: 0;
    box-sizing: content-box;
    display: block;
    font-size: 1.6rem;
    left: 0;
    line-height: 1.33em;
    max-height: 75vh;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 5rem 0 4rem 0;
    position: fixed;
    width: 100%;
    z-index: 9999;
    .checkbox {
        white-space: nowrap
    }
    &__header {
        &-links {
            display: flex;
            flex-wrap: wrap;
            gap: 1em;
            //margin-bottom: 3rem;
            position: relative;
            //width: 100%;
            margin-top: 2rem;

            @include mq($from: l) {
                margin-top: 0;
            }
        }
    }
    &__inner {
        @include use-container();
    }
    &__headline {
        hyphens: auto;
        margin-bottom: 2rem;
    }
    &__settings {
        display: flex;
        flex-wrap: wrap;
        gap: 3rem;
        margin-top: 3rem;
        position: relative;
        &-group {
            max-width: 100%;
            &__head {
                font-size: 1.3em;
            }
            &__description {
                margin-top: 1.5em;
            }
        }
        &-cookie {
            margin-top: 1.5em;
            &__field {
                margin-top: .2em;
                strong {
                    margin-right: .3em;
                    &::after {
                        content: ':';
                    }
                }
            }
        }
    }
    &__tab {
        align-items: center;
        background-color: $color-blue-2;
        border-top-right-radius: 1.5rem;
        border-bottom-right-radius: 1.5rem;
        bottom: 0;
        color: $color-white;
        cursor: pointer;
        display: inline-flex;
        font-size: 1.6rem;
        height: 3rem;
        left: 0;
        max-width: 3rem;
        min-width: 3rem;
        opacity: 1;
        position: fixed;
        text-transform: uppercase;
        transition: max-width .3s ease, opacity .3s ease;
        white-space: nowrap;
        width: 3rem;
        z-index: 9999;
        &-link {
            display: block;
            padding: 0;
            position: relative;
            text-indent: -99rem;
            transition: padding .3s ease, text-indent .3s ease;
        }
        &-icon {
            font-size: 2rem;
            left: .5rem;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
        &.elc-hidden {
            display: none;
        }
        @media (hover:hover) {
            &:hover {
                max-width: 90vw;
                opacity: 1;
                width: auto;
                .elc-cookie-consent__tab-link {
                    padding: 0.2rem 1.5rem 0 3.5rem;
                    text-indent: 0;
                }
            }
        }
    }
    &__interaction {
        display: block;
        margin-top: 3rem;
        position: relative;
        width: 100%;
        &-buttons {
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            gap: 2rem;
            justify-content: space-between;
            margin-top: 6rem;
            position: relative;
            width: 100%;
        }
    }

    &.elc-hidden {
        display: none;
    }

    @include mq($from: l) {
        font-size: 1.8rem;
        max-height: 50vh;
        padding: 6rem 0 5rem 0;
        &__settings {
            &-group {
                max-width: calc(33% - 3rem);
            }
        }
    }

}

#elc {
    &-accept-link {
        display: block;
        .elc-fullwidth & {
            display: none;
        }
    }
    &-hide-detail-link {
        .elc-small & {
            display: none;
        }
    }
    &-save {
        &-link {
            display: none;
            .elc-fullwidth & {
                display: block;
            }
        }
    }
    &-detail {
        &-link {
            .elc-fullwidth & {
                display: none;
            }
        }
    }
}
.elc {
    &-cookie {
        display: none;
        .elc-fullwidth & {
            display: block;
        }
        &-description,
        &-field {
            display: none;
            .elc-fullwidth & {
                display: block;
            }
        }
    }
    &-small {
        .elc-hide-when-small {
            display: none;
        }
    }
    &-text-link {
        text-transform: uppercase;
    }
}

.elc-interaction-container_buttons {
    justify-content: center;

    @include mq($from: l) {
        justify-content: space-between;
    }

    > div {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        gap: 2rem;
        justify-content: center;
    }

    .elc-cookie-consent__interaction-button {
        //margin-bottom: 3rem;

        @include mq($from: l) {
            margin-bottom: 0;
        }
    }

    .hidden {
        display: none;
    }
}
