:root {
  --icon-button-background-color: #{$color-blue-3};
  --icon-button-color: #{$color-blue-1};
  --icon-button-background-color-hover: #{$color-blue-1};
  --icon-button-color-hover: #{$color-white};
}

.icon-button {
  @include use-responsive-sizing(font-size, responsive-map(xs 2rem, xl 3rem));

  background-color: var(--icon-button-background-color);
  color: var(--icon-button-color);
  display: inline-block;
  height: 2em;
  line-height: 0;
  padding: 0.5em;
  transition-property: background-color, color, transform;
  user-select: none;
  width: 2em;

  &:not([disabled]):hover,
  &:focus,
  .has-button-focus:hover &:not([disabled]),
  .has-button-focus:focus-within & {
    background-color: var(--icon-button-background-color-hover);
    color: var(--icon-button-color-hover);
  }

  &:active {
    transform: scale(0.95);
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.icon-button--small {
  @include use-responsive-sizing(font-size, responsive-map(xs 1.6rem, xl 2.4rem));
}

.icon-button--color-linkedin {
  background-color: #0a66c2;
  color: $color-white;
}

.icon-button--color-facebook {
  background-color: #1877f2;
  color: $color-white;
}

.icon-button--color-twitter {
  background-color: #1da1f2;
  color: $color-white;
}

.icon-button--color-youtube {
  background-color: #f00;
  color: $color-white;
}

.icon-button--color-instagram {
  background-color: #e4405f;
  color: $color-white;
}
