:root {
  --link-color: #{$link-color};
  --link-hover-color: #{$link-hover-color};
  --link-icon-size: 1em;
  --link-icon-gap: 0.3em;
}

.link {
  --underline-color: var(--link-color);
  --underline-focus-color: var(--link-hover-color);

  color: var(--link-color);
  transition-property: color;

  &:not([disabled]):hover,
  &:not(.has-invisible-focus):focus {
    color: var(--link-hover-color);
  }
}

.link__icon {
  font-size: var(--link-icon-size);
  margin-left: 0.1em;
  margin-right: var(--link-icon-gap);
}
