.pyramid-graphic {
    background-color: $color-blue-1;
    color: $color-white;
    display: block;
    padding: 10rem 0 8rem 0;
    position: relative;
    width: 100%;

    &--sources {
        padding-bottom: 0;
    }

    &__inner {
        @include use-container();
        display: block;
        position: relative;
    }

    &__headline {
        text-align: center;
        &.headline {
            color: $color-white;
            font-weight: $font-weight-regular;
        }
        @include mq($from: l) {
            text-align: left;
        }

    }
    &__sub-headline {
        margin-top: 1em;
        text-align: center;
        &.headline {
            color: $color-blue-2;
            font-weight: $font-weight-regular;
        }
        @include mq($from: l) {
            text-align: left;
        }
    }
    &__icon-headline {
        align-items: flex-start;
        color: $color-orange;
        display: inline-flex;
        font-size: 2rem;
        justify-content: center;
        margin-bottom: 3rem;
        margin-top: 2rem;
        padding-right: 10%;
        position: relative;
        &__icon {
            font-size: 2em;
            margin-right: .3em;
        }
        &__headline {
            &.headline {
                color: $color-white;
                font-weight: $font-weight-medium;
                margin-top: .3em;
            }
        }
        @include mq($from: l) {
            font-size: 3rem;
            justify-content: flex-start;
            margin-bottom: 0;
            margin-top: 3rem;
        }
    }
    &__graphic {
        align-items: flex-start;
        display: flex;
        flex-wrap: wrap;
        margin-top: 4rem;
        position: relative;
        width: 100%;
        @include mq($from: l) {
            flex-wrap: nowrap;
        }
    }
    &__transition-annotation {
        align-items: flex-start;
        display: flex;
        justify-content: center;
        position: relative;
        width: 100%;
        &s {
            display: flex;
            font-size: 1.6rem;
            flex-direction: column;
            gap: 2rem 0;
            justify-content: flex-end;
            order: 3;
            position: relative;
            width: 100%;
            z-index: 5;
        }
        &__inner {
            background-color: $color-blue-1;
            border: .2rem solid $color-orange;
            border-radius: 2rem;
            display: inline-block;
            line-height: 1.33em;
            padding: 1rem 2rem;
            position: relative;
            z-index: 1;
            &::before {
                background-color: $color-orange;
                border-radius: 0.8em;
                content: attr(data-count);
                color: $color-white;
                font-size: 0.8em;
                left: 50%;
                padding: 0 .6em;
                position: absolute;
                top: 0;
                transform: translate(-50%,-60%);
            }
        }
        &__arrow,
        &__line {
            display: none;
        }
        @include mq($from: l) {
            display: block;
            overflow: hidden;
            position: absolute;
            &s {
                border-top: .2rem solid $color-orange;
                display: block;
                font-size: 2rem;
                height: calc(100% - .4rem);
                margin-bottom: .2rem;
                margin-top: .2rem;
                min-width: 50%;
                order: 1;
                position: absolute;
                width: calc(50% - .2rem);
                z-index: 11;
                &::before {
                    background-color: $color-orange;
                    content: '';
                    display: block;
                    height: .2rem;
                    left: -100vw;
                    position: absolute;
                    top: -.2rem;
                    width: 100vw;
                }
            }
            &__inner {
                flex-grow: 1;
                max-width: 25vw;
                &::before {
                    display: none;
                    content: '';
                }
            }
            &__arrow {
                border: .2rem solid $color-orange;
                border-bottom: none;
                border-left: none;
                display: block;
                height: 2rem;
                position: absolute;
                top: 50%;
                transform: translateY(-50%) rotate(45deg);
                right: 0;
                width: 2rem;
            }
            &__line {
                background-color: $color-orange;
                display: block;
                height: .2rem;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 100%;
            }
        }
    }
    &__pyramid {
        background-color: $color-blue-1;
        display: block;
        font-size: 1.6rem;
        margin-bottom: 3rem;
        order: 2;
        position: relative;
        width: 100%;
        z-index: 10;
        &-border {
            display: block;
            height: calc(100% - .1rem);
            pointer-events: none;
            position: absolute;
            width: 100%;
            z-index: 1;
            path {
                color: $color-blue-2;
                stroke-width: .3rem;
            }
        }
        &-bottom-line {
            display: none;
        }
        &-clip {
            display: block;
            height: 100%;
            position: absolute;
            width: 100%;
        }
        &-section {
            border-bottom: .3rem solid $color-blue-2;
            display: flex;
            justify-content: center;
            padding: 2rem 0;
            position: relative;
            &:first-child {
                padding-top: 10rem;
            }
            &s {
                display: block;
                position: relative;
            }
            &__inner {
                display: block;
                hyphens: auto;
                padding: 0 .3rem;
                position: relative;
                text-align: center;
                width: 100%;
            }
            &::after {
                background-color: $color-orange;
                border-radius: 0.8em;
                bottom: 0;
                color: $color-white;
                content: attr(data-count);
                display: block;
                font-size: 0.8em;
                left: 50%;
                padding: 0.1em 0.4em;
                position: absolute;
                transform: translate(-50%,50%);
            }
        }
        @include mq($from: m) {
            font-size: 2rem;
            margin: 0 auto 4rem auto;
            order: 2;
            width: 75%
        }
        @include mq($from: l) {
            font-size: 3rem;
            margin-bottom: 0;
            min-width: 60%;
            left: 20%;
            order: 2;
            width: 60%;
            &-bottom-line {
                &,
                &::before {
                    background-color: $color-orange;
                    display: block;
                    height: .2rem;
                    left: 0;
                    position: absolute;
                    width: 50%;
                }
                &::before {
                    content: '';
                    top: 0;
                    transform: translateX(-100%);
                }
                &.pyramid-graphic__pyramid-bottom-line--hidden {
                    display: none;
                }
            }
            &-section {
                padding: 4rem 0;
                &:first-child {
                    padding-top: 15rem;
                }
                &::after {
                    content: '';
                    display: none;
                }
            }
        }
    }
    &__side-text {
        color: $color-white;
        display: block;
        font-size: 1.8rem;
        line-height: 1.33em;
        margin-bottom: 4rem;
        order: 1;
        position: relative;
        width: 100%;
        z-index: 5;
        strong {
            color: $color-blue-2;
        }
        &-clip {
            display: none;
        }
        @include mq($from: l) {
            font-size: 2rem;
            left: -10%;
            order: 3;
            width: 50%;
            &-clip {
                display: block;
                float: left;
                height: 20rem;
                position: relative;
                shape-margin: 2rem;
                shape-outside: polygon(0% 0%, 0% 100%, 100% 100%);
                width: 80%;
            }
        }
        @include mq($from: xl) {
            font-size: 2.5rem;
        }
    }
    &__side-loop {
        bottom: 2rem;
        display: none;
        min-width: 400rem;
        position: absolute;
        width: 400rem;
        @include mq($from: l) {
            display: block;
            left: 52.5%;
        }
        @include mq($from: xl) {
            left: 50%;
        }
    }

    &__footnotes {
        display: block;
        font-size: 1.6rem;
        line-height: 1.25em;
        list-style: none;
        margin-top: 3rem;
        position: relative;
        li {
            margin-top: 1em;
            &:first-child {
                margin-top: 0;
            }
            &::before {
                content: attr(data-sup);
                margin-right: .15em;
                vertical-align: super;
            }
        }
        @include mq($from: l) {
            font-size: 2rem;
        }
    }

    &__sources {
        background-color: $color-blue-2;
        color: $color-white;
        display: block;
        margin-top: 6rem;
        padding: 2rem 0;
        position: relative;
        width: 100%;
        &__inner {
            @include use-container();
            display: block;
            position: relative;
        }
    }
}