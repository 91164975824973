@keyframes show-dropdown-select-checkboxes {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.dropdown-select {
  display: inline-block;
  position: relative;
  text-align: left;
}

.dropdown-select__counter {
  background-color: $color-blue-1;
  border-radius: 50%;
  box-shadow: 0 0 0 1px $color-white;
  color: $color-white;
  display: inline-block;
  font-size: 1.6rem;
  font-weight: $font-weight-medium;
  height: 2.4rem;
  line-height: 2.4rem;
  margin-left: 0.8rem;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  transform: translate(-50%, -50%);
  vertical-align: top;
  width: 2.4rem;

  .dropdown-select--inverted & {
    background-color: $color-white;
    color: $color-blue-1;
  }
}

.dropdown-select__checkboxes {
  animation: show-dropdown-select-checkboxes $duration-default;
  background-color: $color-blue-4;
  border-radius: 0.5rem;
  color: $color-blue-1;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 30rem;
  z-index: z-index('dropdown');

  &::before {
    border-left: 1rem solid transparent;
    border-right: 1rem solid transparent;
    content: '';
    height: 0;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 0;
  }

  &[data-popper-placement='bottom']::before {
    border-bottom: 1rem solid $color-blue-4;
    top: -1rem;
  }

  &[data-popper-placement='top']::before {
    border-top: 1rem solid $color-blue-4;
    top: 100%;
  }
}

.dropdown-select__checkbox {
  display: block;
  padding: 1.6rem 2.4rem;

  & + & {
    border-top: 1px solid rgba($color-blue-1, 0.1);
  }
}
