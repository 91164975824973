.field-group__label {
  margin-bottom: 2.4rem;
}

.field-group__field {
  &:not(:last-child) {
    margin-bottom: 2.4rem;
  }

  &[data-conditionally-hidden='true'] {
    display: none;
  }
}

.field-group__error,
.field-group__description {
  margin-top: 2rem;
}
