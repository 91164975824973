.career-entry {
  @include use-responsive-sizing(padding-top padding-bottom --career-entry-inner-padding, responsive-map(xs 4rem, xl 7.2rem));
}

.career-entry__inner {
  @include use-container();
}

.career-entry__form {
  --form-submit-align: center;

  @include use-responsive-sizing(width, (
    s: column-width(s, 12),
    m: column-width(m, 8),
    l: column-width(l, 6),
    xl: column-width(xl, 6),
  ));

  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.career-entry__secondary {
  @include use-responsive-sizing(width, (
    s: column-width(s, 12),
    m: column-width(m, 10),
    l: column-width(l, 8),
    xl: column-width(xl, 8),
  ));

  margin-left: auto;
  margin-right: auto;
  text-align: center;

  .career-entry__primary + & {
    border-top: 1px solid rgba(#000, 0.1);
    margin-top: var(--career-entry-inner-padding);
    padding-top: var(--career-entry-inner-padding);
  }
}

.career-entry__secondary-button:not(:first-child) {
  margin-top: 3.2rem;
}
