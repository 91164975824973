.teaser-grid {
  --teaser-padding: 0;
}

.teaser-grid__inner {
  @include use-container();
}

.teaser-grid__headline {
  @include use-responsive-sizing(text-align, (xs: center, s: left));

  display: flex;
  flex-wrap: wrap;
  margin-bottom: 4rem;
  position: relative;

  .teaser-grid--magazine & {
    //border-bottom: 3px solid $color-blue-1;
    padding-bottom: 2.4rem;
  }
}

.teaser-grid__headline-arrow {
  align-items: center;
  align-self: flex-end;
  color: $color-blue-1;
  display: flex;
  font-size: 2rem;
  height: 2.6rem;
  margin-left: 1.6rem;
}

.teaser-grid__teasers {
  @include use-responsive-sizing(column-gap, $gaps);
  @include use-responsive-sizing(row-gap, map-multiply($gaps, 1.5));
  @include use-responsive-sizing(--teaser-grid-columns, (xs: 2, s: 4, l: 6));

  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: repeat(var(--teaser-grid-columns), 1fr);

  .teaser-grid--1-1 & {
    @include use-responsive-sizing(--teaser-grid-columns, (xs: 2, s: 4, l: 4));
  }
}

.teaser-grid__teaser {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  grid-column: span 2;
  gap: inherit;
}

@include mq($from: s) {
  .teaser-grid--2-1,
  .teaser-grid--2-text-links {
    .teaser-grid__teaser:nth-child(1) {
      grid-column: span 4;
    }
  }

  .teaser-grid--1-2,
  .teaser-grid--text-links-2 {
    .teaser-grid__teaser:nth-child(2) {
      grid-column: span 4;
    }
  }
}

@include mq($from: l) {
  .teaser-grid--2-1,
  .teaser-grid--2-text-links {
    .teaser-grid__teaser:nth-child(1) {
      grid-column: span 4;
    }
  }

  .teaser-grid--1-2,
  .teaser-grid--text-links-2 {
    .teaser-grid__teaser:nth-child(2) {
      grid-column: span 4;
    }
  }
}

.teaser-grid__button {
  margin-top: 6rem;
  text-align: center;
}
