$sticky-wrapper-height: 320px;
$sticky-wrapper-height-xs: 280px;

.process {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__container {
    @include use-responsive-sizing(
      padding,
      (
        xs: 32px 16px,
        l: 80px 0,
      )
    );
    width: 100%;
    padding-left: 5vw;
    padding-right: 5vw;
  }

  &__headline {
    @include use-responsive-sizing(
      margin,
      (
        xs: 32px 0 56px,
        l: 32px 0 93px,
      )
    );
    display: flex;
    justify-content: center;
  }

  &__list {
    width: 100%;
    position: relative;
  }

  .sticky-wrapper {
    @include use-responsive-sizing(
      height,
      (
        xs: $sticky-wrapper-height-xs,
        s: $sticky-wrapper-height,
      )
    );
    @include use-responsive-sizing(
      border-radius,
      (
        xs: 24px,
        l: 500px,
      )
    );
    @include use-responsive-sizing(
      width,
      (
        xs: 90vw,
        l: 960px,
      )
    );
    @include use-responsive-sizing(
      top,
      (
        xs: 20%,
        l: 30%,
      )
    );
    z-index: $z-index-background;
    position: sticky;
    width: 100%;
    margin: 0 auto;
    background-color: $color-blue-3;
    // transition: transform 0.5s ease;
  }

  &__timeline {
    @include use-responsive-sizing(
      transform,
      (
        xs: translateY(-$sticky-wrapper-height),
        l: translateY(-$sticky-wrapper-height * 1.1) translateX(-30px),
      )
    );
    position: relative;
    display: flex;
    flex-direction: column;
    margin-left: 120px;
    width: 100%;
    margin: 0 auto;
    max-width: 567px;
  }

  &__timeline-item {
    @include use-responsive-sizing(
      padding,
      (
        xs: 48px 16px 48px 0,
        l: 64px 0,
      )
    );
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    transition: transform 0.3s ease;

    &-index {
      width: 100px;
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      &.no-height-before {
        &::before {
          height: 0;
        }
      }

      &.no-height-after {
        &::after {
          height: 0;
        }
      }

      &-number {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $color-blue-3;
        border-radius: 100%;
        color: $color-blue-2;
        font-size: 1.2rem;
        font-weight: $font-weight-medium-500;
        height: 32px;
        width: 32px;
        z-index: $z-index-foreground;
        transition: height 0.5s ease, width 0.5s ease,
          background-color 0.5s ease, color 0.5s ease;
      }

      &::before,
      &::after {
        @include use-responsive-sizing(
          height,
          (
            xs: 350%,
            l: 400%,
          )
        );
        content: '';
        position: absolute;
        left: 50%;
        width: 1px;
        background-color: $color-blue-2;
        flex-grow: 1;
      }

      &::before {
        bottom: 110%;
      }

      &::after {
        top: 110%;
      }
    }

    &-image {
      opacity: 0;
      height: 2px;
      max-width: 64px;
      margin-bottom: 24px;
      transition: opacity 0.3s ease, height 0.3s ease, transform 0.3s ease;
      transform: translateY(-40px) translateX(5%);
    }

    &-overline {
      @include font-breadcrumb;
      color: $color-blue-2;
      @include use-responsive-sizing(
        margin-bottom,
        (
          xs: 16px,
          l: 24px,
        )
      );
    }

    &-title {
      width: 90%;
      .paragraph {
        @include font-paragraph;
        transition: transform 0.3s ease;
      }
    }
  }

  &__timeline-item__content {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  //Active
  .active {
    .process__timeline-item-image {
      opacity: 1;
      height: 64px;
      transition: opacity 0.3s ease, height 0.3s ease, transform 0.3s ease;
      transform: scale(1.1) translateY(0) translateX(5%);
    }

    .process__timeline-item-title {
      transition: transform 0.3s ease, font-weight 0.3s ease, color 0.3s ease;
      transform: scale(1.1) translateX(5%);
      .paragraph {
        @include font-paragraph-large;
        font-size: 1.8rem;
        font-weight: $font-weight-bold-700;
        color: $color-blue-2;
        max-width: 90%;
      }
    }

    .process__timeline-item-index-number {
      background-color: $color-blue-2;
      color: white;
      height: 48px;
      width: 48px;
      transition: height 0.5s ease, width 0.5s ease, background-color 0.5s ease,
        color 0.5s ease;
    }
  }
}
