:root {
  --table-font-size: 1.4rem;
}

.table {
  --paragraph-font-size: var(--table-font-size);

  @include use-responsive-sizing(font-size, (
    xs: var(--table-font-size),
    m: calc(var(--table-font-size) * 1.11),
    l: calc(var(--table-font-size) * 1.22),
  ));

  border-collapse: collapse;
  color: $color-blue-1;
  line-height: $line-height-body;
  width: 100%;

  th,
  td {
    padding: 1.6rem 2.4rem;
    text-align: left;
    vertical-align: text-top;
  }

  th {
    background-color: $color-blue-1;
    color: $color-white;
    font-weight: bold;
  }

  tr:nth-child(odd) {
    background-color: $color-blue-4;
  }

  tr:nth-child(even) {
    background-color: $color-blue-3;
  }
}
