@keyframes icon-spin {
  100% {
    transform: rotate(360deg);
  }
}

.icon {
  display: inline-block;
  fill: currentColor;
  height: 1em;
  line-height: 0;
  user-select: all;
  vertical-align: middle;
  width: 1em;
}

.icon--caret-left,
.icon--caret-right {
  width: 0.5em;
}

.icon--caret-top,
.icon--caret-bottom {
  height: 0.5em;
}

.icon--loader {
  animation: icon-spin 1.2s infinite paused;

  .is-requesting & {
    animation-play-state: running;
  }
}
