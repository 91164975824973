.highlight-text {
  @include use-responsive-sizing(padding-top padding-bottom, responsive-map(xs 5.4rem, xl 7.2rem));
}

.highlight-text--blue-1 {
  --headline-color: #{$color-white};
  --paragraph-color: #{$color-white};
  --list-color: #{$color-white};
  --horizontal-rule-color: #{$color-white};

  background-color: $color-blue-1;
  color: $color-white;
}

.highlight-text--blue-2 {
  --headline-color: #{$color-white};
  --paragraph-color: #{$color-white};
  --list-color: #{$color-white};
  --horizontal-rule-color: #{$color-white};

  background-color: $color-blue-2;
  color: $color-white;
}

.highlight-text--blue-3 {
  background-color: $color-blue-3;
  color: $color-blue-1;
}

.highlight-text__inner {
  @include use-container();
}

.highlight-text__content {
  --paragraph-font-size: 2rem;

  @include use-clearfix();

  @include use-responsive-sizing(width, (
    s: column-width(s, 10),
    m: column-width(m, 10),
    l: column-width(l, 10),
    xl: column-width(xl, 8),
  ));

  font-weight: $font-weight-medium;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  > * + * {
    @include use-responsive-sizing(margin-top, responsive-map(xs 3.2rem, xl 4rem));
  }

  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }
}
