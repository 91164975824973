.image-slider--inverted {
  --pager-button-color: #{$color-blue-1};
  --pager-button-background-color: #{$color-white};
  --pager-button-focus-color: #{$color-white};
  --pager-button-focus-background-color: #{$color-blue-1};
  --pager-background-color: rgba(255, 255, 255, 0.2);
  --pager-foreground-color: #{$color-white};
  --headline-color: #{$color-white};
}

.image-slider__inner {
  overflow: hidden;
  position: relative;
}

.image-slider__headline {
  @include use-container();
  @include use-responsive-sizing(height, responsive-map(xs 10rem, xl 15rem));

  align-items: center;
  display: flex;
  inset: 0 0 auto;
  justify-content: center;
  position: absolute;
  text-align: center;
  z-index: 2;
}

.image-slider__slides {
  @include use-break-out();

  align-items: stretch;
  display: flex;
  position: relative;
  transform: translateX(0);
  transition-duration: $duration-long;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
  z-index: 1;
}

.image-slider__slide {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
}

.image-slider__navigation {
  @include use-responsive-sizing(height, responsive-map(xs 10rem, xl 15rem), $end-breakpoint: s, $scoped: true);

  @include mq($until: m) {
    align-items: center;
    display: flex;
    inset: auto 0 0;
    justify-content: center;
    position: absolute;
    z-index: 2;
  }
}

.image-slider__navigation-pager {
  @include use-responsive-sizing(height, responsive-map(xs 10rem, xl 15rem));

  align-items: center;
  display: flex;
  inset: auto 0 0;
  justify-content: center;
  position: absolute;
  z-index: 2;

  @include mq($until: m) {
    display: none;
  }
}

.image-slider__navigation-button {
  background-color: $color-white;
  border-radius: 50%;
  color: $color-blue-1;
  font-size: 1.6rem;
  height: 3.6rem;
  line-height: 0;
  padding: 1rem;
  transition-property: background-color, color;
  width: 3.6rem;
  z-index: 2;

  &[disabled] {
    color: $color-ui-gray;
    cursor: not-allowed;
  }

  &:not([disabled]):hover,
  &:not(.has-invisible-focus):focus {
    background-color: $color-blue-1;
    color: $color-white;
  }

  & + & {
    margin-left: 2.4rem;
  }

  @include mq($from: m) {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

.image-slider__navigation-button--prev {
  left: 2.4rem;
}

.image-slider__navigation-button--next {
  right: 2.4rem;
}
