.pill {
  background-color: $color-blue-4;
  border-radius: 1.6rem;
  color: $color-blue-1;
  display: inline-block;
  font-size: 1.6rem;
  height: 3.2rem;
  line-height: 1;
  padding: 0.8rem 1.6rem;
  transition-property: background-color, color;

  &:any-link:hover,
  &:focus {
    background-color: $color-blue-1;
    color: $color-white;
  }
}
