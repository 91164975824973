:root {
  @include use-responsive-sizing(--logo-grid-columns, (xs: 1, s: 2, m: 3));
  @include use-responsive-sizing(--logo-grid-gap, $gaps);
}

.logo-grid {
  @include use-responsive-sizing(padding-top padding-bottom, responsive-map(xs 4rem, xl 7.2rem));

  background-color: $color-blue-3;
}

.logo-grid__inner {
  @include use-container();
}

.logo-grid__content {
  @include use-responsive-sizing(width, (
    s: column-width(s, 12),
    m: column-width(m, 10),
    l: column-width(l, 10),
    xl: column-width(xl, 8),
  ));

  margin-left: auto;
  margin-right: auto;
}

.logo-grid__headline {
  margin-bottom: 6rem;
  text-align: center;
}

.logo-grid__logos {
  align-content: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: calc(var(--logo-grid-gap) * -0.5);
}

.logo-grid__logo {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: calc(var(--logo-grid-gap) * 0.5);
  width: calc(100% / var(--logo-grid-columns));
}

.logo-grid__logo-image {
  max-width: 20rem;
}

.logo-grid__logo-text {
  @include use-responsive-sizing(font-size, (
    m: 2rem,
    l: 2.2rem,
    xl: 2.4rem,
  ));

  color: $color-blue-1;
  font-weight: $font-weight-medium;
  line-height: $line-height-body;
  margin-top: 1.6rem;
  text-align: center;

  .icon {
    height: 16px;

    &.icon--download {
      margin-top: -5px;
    }
  }
}

.logo-grid__button {
  margin-top: 6rem;
  text-align: center;
}
