// reference of this values is the figma typography page
// Font families
$font-family: 'ATRUVIA', Helvetica, Arial, sans-serif;

// Font weights
$font-weight-light-300: 300; // non-bold headlines
$font-weight-regular-400: 400; // paragraphs, quote content
$font-weight-medium-500: 500; // headlines, intros, strong, bold
$font-weight-bold-700: 700; // h5, buttons

// line heights
$line-height-headline: 1.2; // might be different from figma rendering
$line-height-body: 1.5; // might be different from figma rendering

// headlines (please use scss mixins)
$font-size-headline-1-s: 30px;
$font-size-headline-1-l: 60px;

$font-size-headline-2-s: 26px;
$font-size-headline-2-l: 36px;

$font-size-headline-3-s: 26px;
$font-size-headline-3-l: 32px;

$font-size-headline-4-s: 24px;
$font-size-headline-4-l: 32px;

$font-size-headline-5-s: 22px;
$font-size-headline-5-l: 22px;

// paragraphs (please use scss mixins)
$font-size-paragraph-intro-s: 24px;
$font-size-paragraph-intro-l: 32px;

$font-size-paragraph-large-s: 20px;
$font-size-paragraph-large-l: 28px;

$font-size-paragraph-s: 18px;
$font-size-paragraph-l: 22px;

$font-size-paragraph-small-s: 16px;
$font-size-paragraph-small-l: 18px;

// quotes (please use scss mixins)
$font-size-quote-headline-s: 16px;
$font-size-quote-headline-l: 18px;

$font-size-quote-content-s: 16px;
$font-size-quote-content-l: 18px;

$font-size-quote-caption-s: 16px;
$font-size-quote-caption-l: 18px;

// slider card (please use scss mixins)
$font-size-slider-card-s: 16px;
$font-size-slider-card-l: 20px;

// button (please use scss mixins)
$font-size-button-s: 14px;
$font-size-button-l: 22px;

// breadcrumb (please use scss mixins)
$font-size-breadcrumb-s: 14px;
$font-size-breadcrumb-l: 16px;

:root {
  --headline-color: #{$color-font};
  --headline-line-height: #{$line-height-headline};
  --headline-font-weight: #{$font-weight-medium-500};

  --paragraph-color: #{$color-font};
  --paragraph-line-height: #{$line-height-body};
  --paragraph-font-weight: #{$font-weight-regular-400};
}

@mixin h-base() {
  font-weight: var(--headline-font-weight);
  line-height: var(--headline-line-height);
  color: var(--headline-color);
}

@mixin font-headline-1() {
  // 30 60
  --headline-font-weight: #{$font-weight-light-300}; // override this default with headline.twig highlight option or by setting a new --headline-font-weight value
  @include h-base;
  @include use-responsive-sizing(
    'font-size',
    (
      xs: $font-size-headline-1-s,
      l: $font-size-headline-1-l,
    )
  );
}

@mixin font-headline-2() {
  // 26 36
  @include h-base;
  @include use-responsive-sizing(
    'font-size',
    (
      xs: $font-size-headline-2-s,
      l: $font-size-headline-2-l,
    )
  );
}

@mixin font-headline-3() {
  // 26 32
  --headline-font-weight: #{$font-weight-light-300}; // override this default with headline.twig highlight option or by setting a new --headline-font-weight value
  @include h-base;
  @include use-responsive-sizing(
    'font-size',
    (
      xs: $font-size-headline-3-s,
      l: $font-size-headline-3-l,
    )
  );
}

@mixin font-headline-4() {
  // 24 32
  @include h-base;
  @include use-responsive-sizing(
    'font-size',
    (
      xs: $font-size-headline-4-s,
      l: $font-size-headline-4-l,
    )
  );
}

@mixin font-headline-5() {
  // 22 22
  --paragraph-font-weight: #{$font-weight-bold-700};
  @include h-base;
  @include use-responsive-sizing(
    'font-size',
    (
      xs: $font-size-headline-5-s,
      l: $font-size-headline-5-l,
    )
  );
}

@mixin font-button() {
  // 14 22
  --paragraph-font-weight: #{$font-weight-bold-700};
  @include h-base;
  @include use-responsive-sizing(
    'font-size',
    (
      xs: $font-size-button-s,
      l: $font-size-button-l,
    )
  );
}

@mixin p-base() {
  font-weight: var(--paragraph-font-weight);
  line-height: var(--paragraph-line-height);
  color: var(--paragraph-color);
}

@mixin font-paragraph-intro() {
  // 24 32
  --paragraph-font-weight: #{$font-weight-medium-500};
  @include p-base;
  @include use-responsive-sizing(
    'font-size',
    (
      xs: $font-size-paragraph-intro-s,
      l: $font-size-paragraph-intro-l,
    )
  );
}

@mixin font-paragraph-large() {
  // 20 28
  @include p-base;
  @include use-responsive-sizing(
    'font-size',
    (
      xs: $font-size-paragraph-large-s,
      l: $font-size-paragraph-large-l,
    )
  );
}

@mixin font-paragraph() {
  // 18 22
  @include p-base;
  @include use-responsive-sizing(
    'font-size',
    (
      xs: $font-size-paragraph-s,
      l: $font-size-paragraph-l,
    )
  );
}

@mixin font-paragraph-small() {
  // 16 18
  @include p-base;
  @include use-responsive-sizing(
    'font-size',
    (
      xs: $font-size-paragraph-small-s,
      l: $font-size-paragraph-small-l,
    )
  );
}

@mixin font-quote-headline() {
  // 16 18
  @include p-base;
  @include use-responsive-sizing(
    'font-size',
    (
      xs: $font-size-quote-headline-s,
      l: $font-size-quote-headline-l,
    )
  );
}

@mixin font-quote-content() {
  // 16 18
  @include p-base;
  @include use-responsive-sizing(
    'font-size',
    (
      xs: $font-size-quote-content-s,
      l: $font-size-quote-content-l,
    )
  );
}

@mixin font-quote-cation() {
  @include p-base;
  @include use-responsive-sizing(
    'font-size',
    (
      xs: $font-size-quote-cation-s,
      l: $font-size-quote-cation-l,
    )
  );
}

@mixin font-slider-card() {
  // 16 20
  @include p-base;
  @include use-responsive-sizing(
    'font-size',
    (
      xs: $font-size-slider-card-s,
      l: $font-size-slider-card-l,
    )
  );
}

@mixin font-breadcrumb() {
  // 14 16
  @include p-base;
  @include use-responsive-sizing(
    'font-size',
    (
      xs: $font-size-breadcrumb-s,
      l: $font-size-breadcrumb-l,
    )
  );
}
